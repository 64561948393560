import React from 'react';
import { Form, Input, Button, Carousel, message } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import authApi from '../../servapi/auth';
import { Link, useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import './LoginForm.less';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const LoginForm = inject('userStore')(({ userStore }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onFinish = async (values) => {

    try {
      userStore.reset();
      const { token } = await authApi.login(values);

      if (!token) {
        message.error('Login failed, please check if your email and password are correct.');
        return;
      }

      console.log(token);
      cookie.set('token', token);
      
      // await userStore.loadUserInfo();
      await userStore.loadUserInfo();
      navigate({
        pathname: '/dashboard',
      });
    } catch(e) {
      console.log(e);
    }

    // authApi.login(values)
    //   .then(({ token }) => {
    //     console.log(token);
    //     cookie.set('token', token);
    //     // onSuccess && onSuccess();

    //     navigate({
    //       pathname: '/dashboard',
    //     })

    //   })
    //   .catch(console.log)
  };

  return (
    <div className="login-banner-row">
      <div className="login-box">
        <a className="login-href" href="">
          <Link to="/">
            <img src="/images/logo_t.png" alt="" style={{ width: '300px'}} />
          </Link>
        </a>
        <Form
          className="ant-legacy-form ant-legacy-form-horizontal login-form"
          onFinish={onFinish}
        >
          <Form.Item
            className="ant-row ant-legacy-form-item"
            name="username"
            style={{ rowGap: '0px' }}
          >
            <Input size="large" placeholder={t("login.email")} />
          </Form.Item>
          <Form.Item
            className="ant-row ant-legacy-form-item"
            name="password"
            style={{ rowGap: '0px' }}
          >
            <Input.Password
              size="large"
              placeholder={t("login.password")}
              iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <></>)}
            />
          </Form.Item>
          <Form.Item
            className="ant-row ant-legacy-form-item"
            style={{ rowGap: '0px' }}
          >
            <Button type="primary" htmlType="submit" size="large" className="login-form-button">
                {t("login.loginBtn")}
            </Button>
          </Form.Item>
        </Form>
        <div className="forgot-password">
          <a target="_blank" href="">{t("login.forgot")}？</a>
        </div>
        <div className="goto-register">
          <Link to="/register">
            <span>{t("login.create")}</span>
          </Link>
        </div>
      </div>
      {/* <div className="banner-box">
        <Carousel autoplay>
          <div>
            <img className="banner-box-image" src="https://bd-images-prod-1252252286.cos.accelerate.myqcloud.com/root_c3c9efd60f7f7fb16f57fd7a74bd71c3/my-images/2024/04/23/17138598103610.png" alt="Banner 1" />
          </div>
          <div>
            <img className="banner-box-image" src="https://bd-images-prod-1252252286.cos.accelerate.myqcloud.com/root_c3c9efd60f7f7fb16f57fd7a74bd71c3/my-images/2024/04/23/17138599376620.png" alt="Banner 2" />
          </div>
          <div>
            <img className="banner-box-image" src="https://support-cdn.buckydrop.com/root_2933ebfb63209b6c21e51dce84f1374e/my-images/2022/04/27/1651054604612n.jpg" alt="Banner 3" />
          </div>
        </Carousel>
      </div> */}
    </div>
  );
});

export default LoginForm;
