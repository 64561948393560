import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';


const api = create({ baseURL: ''})

class SaleOrderApi {

  async getSaleOrders(params) {
    return await api.get(`/api/v1/sale_orders?${qs.stringify(params)}`);
  }

  async getSaleOrderFulfillments(params) {
    return await api.get(`/api/v1/sale_order_fulfillments?${qs.stringify(params)}`);
  }

  async syncSaleOrders(id) {
    return await api.post(`/api/v1/shops/${id}/sale_orders/sync`);
  }
}

const saleOrderApi = new SaleOrderApi();
export default saleOrderApi;