import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';


const api = create({ baseURL: ''})

class PurchasePoolApi {

  async getPurchasePoolProducts(params) {
    return await api.get(`/api/v1/purchase_pool/products?${qs.stringify(params)}`);
  }

  async refresh() {
    return await api.post(`/api/v1/purchase_pool/refresh`);
  }

  async search(params) {
    return await api.get(`/api/v1/purchase_pool/search?${qs.stringify(params)}`)
  }
}

const purchasePoolApi = new PurchasePoolApi();
export default purchasePoolApi;