import React from 'react';

import './ProductEdit.less';
import Dashboard from './Dashboard';
import { Breadcrumb, Col, Row, Typography, Card, Divider, Checkbox, Button, Input, Select, Form, Upload, Image, Table   } from 'antd';
import { QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const ProductDetailsCard = () => {
  return (
    <Card className="ant-card-bordered">
      <div className="ant-card-body">
        <div className="product-msg">
          <span>商品状态</span>
          <div className="status available">可用的</div>
          <div className="ant-divider ant-divider-vertical" role="separator" style={{ margin: '0px 20px' }}></div>
          <span>最新更新时间2024/06/23 00:17:59&nbsp;</span>
          <span><a target="_blank" href="https://detail.1688.com/offer/772066725288.html">查看原始商品&gt;</a></span>
        </div>
        <Divider className="ant-divider-horizontal" style={{ margin: '15px 0px' }} />
        <Row gutter={0}>
          <Col span={24}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={24}>
                  <Checkbox value="" defaultChecked={true}>
                    将商品标记为在店铺销售
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="">
                    开启自动更新&nbsp;
                    <span role="img" aria-label="question-circle">
                      <QuestionCircleOutlined />
                    </span>
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const ProductInfoCard = () => {
  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  return (

      <Card className="ant-card ant-card-bordered" title="商品信息">
        <div className="ant-card-body">
          <span className="product-msg">
            来源于
            <div className="status good-type">第三方供应</div>
            <Button type="link" className="ml-20">
              获取最新的商品数据
            </Button>
          </span>
          <div className="ant-divider ant-divider-horizontal" style={{ margin: '14px 0px 8px' }} />
          <Form.Item label="商品名称" colon={false}>
            <Input.TextArea rows={1} defaultValue="纯棉短袖t恤女2024年新款宽松韩系时髦小众减龄显瘦上衣夏装ins潮" />
          </Form.Item>
          <Form.Item label={<div className="ant-space ant-space-horizontal ant-space-align-center">
            <div className="ant-space-item">商品分类</div>
            <div className="ant-space-item">
              <QuestionCircleOutlined />
            </div>
          </div>} colon={false}>
            <Select disabled defaultValue="T恤">
              <Option value="T恤">T恤</Option>
              {/* Add more Option components as needed */}
            </Select>
          </Form.Item>
        </div>
      </Card>
  );
};



const { TextArea } = Input;
const { Option } = Select;

const columns = [
  {
    title: (
      <div className="ant-table-selection">
        <label className="ant-checkbox-wrapper ant-checkbox-wrapper-checked">
          <span className="ant-checkbox ant-checkbox-checked">
            <input type="checkbox" className="ant-checkbox-input" value="" checked="" />
            <span className="ant-checkbox-inner"></span>
          </span>
        </label>
      </div>
    ),
    dataIndex: 'selection',
    key: 'selection',
  },
  {
    title: '图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: (
      <TextArea className="ant-input" defaultValue="颜色" autoSize={{ minRows: 1, maxRows: 3 }} />
    ),
    dataIndex: 'color',
    key: 'color',
  },
  {
    title: (
      <TextArea className="ant-input" defaultValue="尺码" autoSize={{ minRows: 1, maxRows: 3 }} />
    ),
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: '成本价',
    dataIndex: 'cost',
    key: 'cost',
    render: () => (
      <>
        成本价内容{/* 这里可能需要进一步的数据呈现 */}
      </>
    ),
  },
  {
    title: '国内运费',
    dataIndex: 'domesticShipping',
    key: 'domesticShipping',
  },
  {
    title: (
      <div>
        销售价格
      </div>
    ),
    dataIndex: 'sellingPrice',
    key: 'sellingPrice',
    render: () => (
      <>
        销售价格内容{/* 这里可能需要进一步的数据呈现 */}
      </>
    ),
  },
  {
    title: (
      <div>
        价格比较
      </div>
    ),
    dataIndex: 'priceComparison',
    key: 'priceComparison',
    render: () => (
      <Select defaultValue="" className="ant-select" style={{ width: 149 }}>
        {/* 这里添加选项 */}
      </Select>
    ),
  },
  {
    title: (
      <div>
        重量/单位
        <Button type="primary" size="small" className="ml-5">
          设置新值
        </Button>
      </div>
    ),
    dataIndex: 'weightUnit',
    key: 'weightUnit',
    render: () => (
      <Select defaultValue="g" className="ant-select" style={{ width: 60 }}>
        <Option value="g">g</Option>
      </Select>
    ),
  },
  {
    title: (
      <div>
        商品库存
      </div>
    ),
    dataIndex: 'stock',
    key: 'stock',
    render: () => (
      <Select defaultValue="" className="ant-select" style={{ width: 149 }}>
        {/* 这里添加选项 */}
      </Select>
    ),
  },
];

const data = [
  {
    key: '1',
    selection: '选择内容', // 这里可能需要进一步的数据呈现
    image: '图片内容',
    color: '颜色内容',
    size: '尺码内容',
    cost: '成本价内容',
    domesticShipping: '国内运费内容',
    sellingPrice: '销售价格内容',
    priceComparison: '价格比较内容',
    weightUnit: '重量/单位内容',
    stock: '商品库存内容',
  },
  // 可能有更多数据项
];

const ProductAttrsCard = () => (
  <Card bordered>
    {/* Card Head部分 */}
    <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
        <div className="ant-card-head-title">
          <div className="flex-center-vertical">
            <p className="variants-label">
              <span>属性&nbsp;</span>
              <span>(42/42)</span>
            </p>
            <div className="text-red ml-20 text-16"></div>
          </div>
        </div>
      </div>
    </div>
    {/* Card Body部分 */}
    <div className="ant-card-body">
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1280, y: 530 }}
        pagination={false}
      />
    </div>
  </Card>
);


const { Meta } = Card;

const ImageUploadForm = () => {
  return (
      <Row className="ant-row ant-legacy-form-item spu-images" style={{ rowGap: '0px' }}>
        <Col className="ant-col ant-legacy-form-item-control-wrapper">
          <div className="ant-legacy-form-item-control">
            <div className="ant-legacy-form-item-children">
              <Card bordered={true}>
                <div className="ant-card-head">
                  <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">
                      图片
                      <Checkbox className="ant-checkbox-wrapper" style={{ marginLeft: 16 }}>
                        全选
                      </Checkbox>
                      <span className="img-count-number orange" style={{ color: 'rgb(250, 173, 20)' }}>
                        5
                      </span>
                      <span className="img-count-number">&nbsp;/&nbsp;11</span>
                      <a href="https://www.befunky.com/create" target="_blank" style={{ marginLeft: 24, float: 'right' }}>
                        图片编辑器
                      </a>
                    </div>
                  </div>
                </div>
                <div className="ant-card-body">
                  <div className="spu-imgs-wrapper nowrap">
                    <div className="spu-imgs-row">
                      {/* Placeholder for Upload component */}
                      <div className="spu-img-col">
                        <Upload
                          accept="image/jpg,image/jpeg,image/png,image/gif,undefined"
                          multiple
                          className="ant-upload ant-upload-select ant-upload-select-picture-card"
                          showUploadList={false}
                        >
                          <span className="ant-upload">
                            <span style={{ fontSize: 18, marginRight: 8 }}>
                              <span role="img" aria-label="plus" className="anticon anticon-plus">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                  <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                                  <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                                </svg>
                              </span>
                              上传
                            </span>
                          </span>
                        </Upload>
                      </div>
                      {/* Placeholder for Image components */}
                      <div className="spu-img-col">
                        <Image
                          src="https://cbu01.alicdn.com/img/ibank/O1CN01wnampC1nSoVkMjM64_!!2211356365089-0-cib.jpg"
                          width={174}
                          preview={false}
                        />
                      </div>
                      <div className="spu-img-col">
                        <Image
                          src="https://cbu01.alicdn.com/img/ibank/O1CN01yeh1Ez1nSoVZK0DWb_!!2211356365089-0-cib.jpg"
                          width={174}
                          preview={false}
                        />
                      </div>
                      <div className="spu-img-col">
                        <Image
                          src="https://cbu01.alicdn.com/img/ibank/O1CN01M8WmXC1nSoVerJRci_!!2211356365089-0-cib.jpg"
                          width={174}
                          preview={false}
                        />
                      </div>
                      <div className="spu-img-col">
                        <Image
                          src="https://cbu01.alicdn.com/img/ibank/O1CN01ICjBAO1nSoVhttekY_!!2211356365089-0-cib.jpg"
                          width={174}
                          preview={false}
                        />
                      </div>
                      {/* Add more Image components as needed */}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
  );
};

const ProductDescriptionEditor = () => {
  return (
      <Row style={{ rowGap: '0px' }}>
        <Col span={18} style={{ paddingRight: '15px' }}>
          <Card>
            <div className="ant-card-body" style={{ padding: '0px' }}>
              {/* CKEditor 或其他富文本编辑器组件 */}
              {/* 这里省略 CKEditor 相关内容 */}
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Row className="ant-legacy-form-item" style={{ rowGap: '0px' }}>
            <Col className="ant-legacy-form-item-control-wrapper">
              <div className="ant-legacy-form-item-control">
                <div className="ant-card ant-card-bordered right-images">
                  <div className="ant-card-head">
                    <div className="ant-card-head-wrapper">
                      <div className="ant-card-head-title">
                        <Row style={{ rowGap: '0px' }}>
                          <Col span={16} style={{ minWidth: '190px' }}>描述
                            <Checkbox style={{ marginLeft: '16px' }}>全选</Checkbox>
                          </Col>
                          <Col span={6} style={{ minWidth: '40px', paddingLeft: '10px' }}>
                            <span style={{ color: 'rgb(250, 173, 20)' }}>0</span>&nbsp;/&nbsp;11
                          </Col>
                        </Row>
                        <Row style={{ rowGap: '0px' }}>
                          <Col span={16} style={{ minWidth: '190px', paddingLeft: '32px' }}>
                            <Checkbox style={{ marginLeft: '16px' }}>Select product details</Checkbox>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="ant-card-body">
                    <Row className="spu-imgs-row">
                      {/* 使用 Ant Design 的 Card 和 Image 组件 */}
                      <Col span={24} className="spu-img-col">
                        <Card className="spu-img" hoverable>
                          <div style={{ height: '174px', width: '174px', display: 'inline-block', verticalAlign: 'top', border: 'none', overflow: 'hidden', borderRadius: '0px' }}>
                            <img alt="" src="https://cbu01.alicdn.com/img/ibank/O1CN01wnampC1nSoVkMjM64_!!2211356365089-0-cib.jpg" style={{ opacity: 1, verticalAlign: 'top', borderRadius: '0px', height: '100%', width: '100%' }} />
                          </div>
                          <Checkbox className="spu-img-check">
                            <CheckOutlined />
                          </Checkbox>
                        </Card>
                      </Col>
                      {/* 其他图片列也使用类似的 Card 和 Image 组件 */}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
  );
}


export default props => {

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '126px' }}>
          <Row className="ant-row-space-between ant-row-middle" style={{ padding: '0px 24px', background: '#fff', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px', rowGap: 0 }}>
            <Col span={24} style={{ paddingTop: 12 }}>
              <Row style={{ rowGap: 0 }}>
                <Col span={24}>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="">商品列表</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>编辑商品</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className="ant-row-middle" style={{ rowGap: 0 }}>
                <Col span={12}>
                  <div className="flex-center-vertical">
                    <Typography.Title level={3} ellipsis={{ tooltip: '纯棉短袖t恤女2024年新款宽松韩系时髦小众减龄显瘦上衣夏装ins潮' }} style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                      纯棉短袖t恤女2024年新款宽松韩系时髦小众减龄显瘦上衣夏装ins潮
                    </Typography.Title>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inner-content relative">
        <div className="ant-spin-nested-loading">
          <div className="ant-spin-container">
            <div className="edit">
              <Form className="ant-legacy-form ant-legacy-form-horizontal">
                <ProductDetailsCard />
                <ProductInfoCard />
                <ImageUploadForm />
                <ProductAttrsCard />
                <ProductDescriptionEditor />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}