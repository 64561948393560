import React, { useRef, useEffect, useState } from 'react';
import { Layout, Row, Col, Input, Tabs } from 'antd';
import { Spin } from 'antd';
import Product from './ProductCard';
import productApi from '../../servapi/product';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;


const ProductScrollerMore = ({ keyword }) => {
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [pageData, setPageData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    totalPage: 1,
    pageSize: 10,
    totalRecords: 0,
  })

  React.useEffect(() => {
    setLoading(true);
    productApi.search({
      "beginPage": pagination.currentPage,
      "pageSize": pagination.pageSize,
      "country": i18n.language,
      keyword,
    })
      .then(({ data = [] }) => {
        setPageData([
          ...pageData,
          ...data,
        ]);
        setLoading(false);
        // setPagination({
        //   ...pagination,
        //   currentPage,
        //   totalPage,
        //   totalRecords,
        // })
      })
  }, [keyword, i18n.language, pagination]);

  const handleCurrentPageChange = currentPage => {
    if (loading) {
      return;
    }
    setPagination({
      ...pagination,
      currentPage
    })
  }

  // useRef用来获取滚动容器的引用
  const scrollContainerRef = useRef(null);

  // 监听滚动事件的处理函数
  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // 当滚动到底部时，进行加载下一页的操作
      console.log(scrollContainer.scrollTop + scrollContainer.clientHeight, scrollContainer.scrollHeight)
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 60) {
        // 增加页面数，并调用传入的onNextPage函数
        const nextPage = pagination.currentPage + 1;
        handleCurrentPageChange(nextPage);
      }
    }
  };

  // useEffect用来在组件挂载时添加滚动事件监听
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // 添加滚动事件监听
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    
    // 组件卸载时清除事件监听
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]); // 依赖项中只有handleScroll，确保只在handleScroll函数变化时重新注册事件

  return (
    <div className="d-flex flex-wrap mx--5" ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight: '80vh' }}>
      {(pageData || []).map(product => <Product key={product.id} product={product} />)}
      {loading && (
        <div style={{
          height: 42,
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

const ProductsFeaturedHotSection = props => {
  const { t, i18n } = useTranslation();
  const [pageData, setPageData] = React.useState({});
  const [current, setCurrent] = React.useState("Women's Fashion");
  const [currentPage, setCurrentPage] = useState(0);

  React.useEffect(() => {
    productApi.search({
      "beginPage": currentPage,
      "country": i18n.language,
      keyword: current,
      "pageSize": 10,
    })
      .then(setPageData)
  }, [current, i18n.language, currentPage])


  const handleNextPage = currentPage => {
    setCurrentPage(currentPage);
  }

  return (
    <section className="products-main-section">
      <header className="products-main-section-header">
        <h2 className="products-main-section-title">{t('productIndex.popularProducts.title')}</h2>
      </header>
      <div className="products-main-featured-hot-products">
        <Tabs activeKey={current} onChange={setCurrent}>
          <TabPane tab={t('productIndex.popularProducts.tabs.womensFashion')} key="Women's Fashion">
            <ProductScrollerMore key="Women's Fashion" keyword={ current }/>
          </TabPane>
          <TabPane tab={t('productIndex.popularProducts.tabs.mensFashion')} key="Men's Fashion">
            <ProductScrollerMore key="Men's Fashion" keyword={ current }/>
          </TabPane>
          <TabPane tab={t('productIndex.popularProducts.tabs.clothingAccessories')} key="Clothing Accessories">
            <ProductScrollerMore key="Clothing Accessories" keyword={ current }/>
          </TabPane>
          <TabPane tab={t('productIndex.popularProducts.tabs.beautyCare')} key="Beauty & Care">
            <ProductScrollerMore key="Beauty & Care" keyword={ current }/>
          </TabPane>
          <TabPane tab={t('productIndex.popularProducts.tabs.outdoorSports')} key="Outdoor Sports">
            <ProductScrollerMore key="Outdoor Sports" keyword={ current }/>
          </TabPane>
        </Tabs>
      </div>
    </section>
  )
}

export default ProductsFeaturedHotSection;