import React from 'react';
import { Result, Button } from 'antd';
import './ActivateAccount.less';

export default props => {

  const contact = () => {
    var email = 'hello@pintaback.com';
    var subject = 'Pintaback Account Activation Request';
    var body = 'I registered a new account on Pintaback, but it has not been activated yet. Please assist in activating my account.';
    var mailtoLink = 'mailto:' + email + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    window.location.href = mailtoLink;
  }

  return (
    <div className='activate-account'>
      <Result
        status="warning"
        title="Account not yet activated. Please contact the official administrator: hello@pintaback.com"
        extra={
          <Button type="primary" key="console" onClick={contact}>
            Contact Administrator
          </Button>
        }
      />
    </div>
  )
}
