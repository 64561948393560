import { Avatar, Badge, Col, Divider, Row, Dropdown, Menu, Typography } from 'antd';
import { BellOutlined, UserOutlined, BookOutlined, LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';
import { observer, inject } from 'mobx-react';
import './UserInfo.less';
import { lazy } from 'react';
import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';





const UserInfo = inject('userStore')(observer(({ userStore }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    cookie.remove('token');
    navigate({
      pathname: "/login"
    })
  }

  const userInfoMenus = [
    {
      key: '1',
      label: (
        <Row>
          <Col span={5}>
            <Avatar size="large" style={{ backgroundColor: '#fde3cf', color: '#f56900' }} src="https://cdn.buckydrop.com/starit-buckydrop/media/avatar.png" />
          </Col>
          <Col span={19}>
            <div className="li-el flex items-center h-full"><Typography.Text ellipsis style={{ maxWidth: 190, minWidth: 190 }}>{userStore?.userInfo?.username}</Typography.Text></div>
            {/* <div className="li-el"><Typography.Text type="secondary">可用余额</Typography.Text><br /><strong>CN ¥0.00</strong></div> */}
          </Col>
        </Row>
      ),
    },
    // {
    //   key: '2',
    //   label: (
    //     <      a href=""><UserOutlined /> 个人资料和密码</a>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: (
    //     <a href=""><BookOutlined /> 账户余额</a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <div onClick={handleLogout}><LogoutOutlined /> {t("dashboard.userInfo.logout")}</div>
      )
    }
  ];

  const menu = (
    <Menu>
      <Menu.Item key="important">
        <a href="">
          <span style={{ width: 200, display: 'inline-block' }}>未读重要消息</span>
          <Badge count={0} />
        </a>
      </Menu.Item>
      <Menu.Item key="others">
        <a href="">
          <span style={{ width: 200, display: 'inline-block' }}>其他未读邮件</span>
          <Badge count={1} />
        </a>
      </Menu.Item>
      <Menu.Item key="more" style={{ textAlign: 'right' }}>
        <a href="">更多 &gt;</a>
      </Menu.Item>
    </Menu>
  );

  const bellInfoMenus = [
    {
      key: '1',
      label: (
        <a href="">
          <span style={{ width: 200, display: 'inline-block' }}>未读重要消息</span>
          <Badge count={0} />
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a href="">
          <span style={{ width: 200, display: 'inline-block' }}>其他未读邮件</span>
          <Badge count={1} />
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a href="">更多 &gt;</a>
      ),
    },
  ];

  return (
    <div className="user-info">
      <Row gutter={[4, 0]}>
        {/* <Col span={3} style={{ paddingLeft: 4, paddingRight: 4, textAlign: 'center' }}>
          <Dropdown menu={{ items: bellInfoMenus }} placement="bottomCenter">
            <div className="header-bell">
              <Badge>
                <BellOutlined style={{ fontSize: 18, color: '#fff' }} />
                <sup className="ant-scroll-number ant-badge-dot" data-show="true"></sup>
              </Badge>
            </div>
          </Dropdown>
        </Col> */}
        <Col span={3} style={{ paddingLeft: 4, paddingRight: 4, textAlign: 'center' }}>
          <Divider type="vertical" style={{ borderColor: '#fff' }} />
        </Col>
        <Col span={18} style={{ paddingLeft: 4, paddingRight: 4 }}>
          <Dropdown menu={{ items: userInfoMenus }} placement="bottomRight">
            <Row gutter={[8, 0]}>
              <Col span={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                <Avatar size={32} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} src="https://cdn.buckydrop.com/starit-buckydrop/media/avatar.png" />
              </Col>
              <Col span={18} className="text-left" style={{ paddingLeft: 8, paddingRight: 8 }}>
                <p className="user-name">{userStore?.userInfo?.username}</p>
              </Col>
            </Row>
          </Dropdown>
        </Col>
      </Row>
    </div>
  )
}));

export default UserInfo;