import opten from './options_en.json';
import optru from './options_ru.json';
import optes from './options_es.json';
import optcn from './options_cn.json';

const optionsMap = {
  'en-US': opten,
  'ru-RU': optru,
  'es-ES': optes,
  'zh-CN': optcn,
}

export default optionsMap;