import { create } from "../util/request";
import qs from 'qs';

const api = create({ baseURL: ''})
class AuthApi {

  async login(params) {
    return await api.post('/api/v1/login', params);
  }

  async register(params) {
    return await api.post('/api/v1/register', params);
  }

  async checkEmail(params) {
    return await api.get(`/api/v1/checkEmail?${qs.stringify(params)}`)
  }

  async getUserInfo() {
    return await api.get('/api/v1/auth/userinfo');
  }
}

const authApi = new AuthApi();
export default authApi;