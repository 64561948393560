import React from 'react';
import RegisterForm from './component/RegisterForm';
import LoginForm from './component/LoginForm';

import './LoginIndex.less';

export default props => {


  return (
    <div className="canvas-bg">
      <LoginForm />
    </div>
  )
}