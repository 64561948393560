import React from 'react';
import { Layout, Row, Col, Input, Button, Upload, message, Tooltip } from 'antd';
import { Carousel } from 'antd';
import ProductsCategory from './component/ProductsCategory';
import ProductsMainFlows from './component/ProductsMainFlows';
import { SearchOutlined, CameraOutlined } from '@ant-design/icons';
import { Tabs, Space } from 'antd';
import Product from './component/ProductCard';
import ProductsSearchSection from './component/ProductsSearchSection';
import ProductsFeaturedHotSection from './component/ProductsRecommendSection';
import Dashboard from './Dashboard';
import ProductBestSellers from './component/ProductBestSellers';
import axios from 'axios';
import './ProductsIndex.less';
import { useTranslation } from 'react-i18next';
import { FileSearchOutlined, HeartOutlined, UpSquareOutlined, GiftOutlined } from '@ant-design/icons'
import productApi from '../servapi/product';
const { TabPane } = Tabs;
const ProductsMainCarousel = () => {
  return (
    <Carousel className="ant-carousel">
      <div className="slick-slider slick-initialized">
        <div className="slick-list">
          <div className="slick-track" style={{ width: '680px', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}>
            <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style={{ outline: 'none', width: '680px' }}>
              <div>
                <a className="products-main-carousel-item" href="" tabIndex="-1" style={{ width: '100%', display: 'inline-block' }}>
                  <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e0625e36a25f4895b1ca8d11ee1eea09_mergeImage.png" alt="Product" style={{ width: '100%' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};


const { Search } = Input;


const SearchWithUpload = ({ onSearch, onSearchImageUpload }) => {
  const { t } = useTranslation();

  const handleUpload = async  ({ file }) => {
    // 处理上传逻辑，可以在这里获取上传的文件信息
    console.log('Uploaded file:', file);
    // 这里可以根据实际需求处理上传后的操作
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await productApi.uploadSearchImage(formData);
      onSearchImageUpload(res);
    } catch(error) {
        // 处理上传失败的情况
        message.error('上传失败');
        console.error('上传失败:', error);
    }

    // axios.post('/api/v1/upload_search_image', formData)
    //   .then(response => {
    //     console.log(response)
    //     // 处理上传成功的情况
    //     // message.success('上传成功');
    //     // 在这里处理接口返回的其他逻辑

    //     onSearchImageUpload(response.data);
    //   })
    //   .catch(error => {
    //     // 处理上传失败的情况
    //     message.error('上传失败');
    //     console.error('上传失败:', error);
    //   });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Upload
        showUploadList={false}
        beforeUpload={() => false} // 取消默认上传行为
        onChange={handleUpload}
      >
        <Tooltip title={t("productIndex.search.uploadText")}>
          <Button className='search-upload-icon'>
            <CameraOutlined style={{ fontSize: 18 }} />
          </Button>
        </Tooltip>
      </Upload>
      <Search
        placeholder={t("productIndex.search.placeholder")}
        allowClear
        enterButton={t("productIndex.search.btnText")}
        size="large"
        onSearch={onSearch}
        style={{ marginLeft: 10 }}
      />
    </div>
  );
};

export default props => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = React.useState();


  const handleSearch = value => {
    if (!value) {
      return;
    }

    setSearchParams({ keyword: value });
  }

  const handleSearchImage = ({ imageId }) => {
    if (!imageId) {
      return;
    }

    setSearchParams({ imageId })
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '126px' }}>
          <h5 className="page_head_title text-bold text-24 pt-12 cursor-pointer" onClick={() => setSearchParams(null)}>{t('productIndex.title')}</h5>
          <div id="admin-products-page-header-bottom">
            <div className="products-main-search">
              <div className="products-main-search-top">
                <div className="products-main-search-platforms"></div>
                <div className="products-main-search-input">
                  <SearchWithUpload onSearch={handleSearch} onSearchImageUpload={handleSearchImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-content relative px-[16px]">
        <div className="admin-products-page" style={{ marginTop: 142 }}>
          <div className={`products-main ${searchParams ? 'hidden' : ''}`}>
            <div className="products-main-top">
              <div className="products-main-hero-banner">
                <div className="products-main-box full-width products-main-box-categories">
                  <header className="products-main-box-header">
                    <h5 className="products-main-box-title">{t("productIndex.category.title")}</h5>
                  </header>
                  <div className="products-main-box-body">
                    <div className="products-main-categories" id="cascader-container">
                      <ProductsCategory onChange={categoryIds => setSearchParams({ categoryId: categoryIds[categoryIds.length - 1] })} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="products-main-carousel ml-auto">
                    <ProductsMainCarousel />
                  </div>
                  <div className="products-main-flows">
                    <div className="products-main-flow">
                      <div className="products-main-flow-title">
                        <div className="text-bold text-16">{t("productIndex.steps.pushToStore")}</div>
                      </div>
                      <div className="products-main-flow-steps">
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <SearchOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 1</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.findProducts")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <UpSquareOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 2</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.pushToStore")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <HeartOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 3</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.procurement")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <GiftOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 4</span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.fulfillment")}</div>
                        </div>
                      </div>
                    </div>
                    <div className="products-main-flow">
                      <div className="products-main-flow-title">
                        <div className="text-bold text-16">{t("productIndex.steps.directProcurement")}</div>
                      </div>
                      <div className="products-main-flow-steps">
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <SearchOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 1</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.findProducts")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <UpSquareOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 2</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.pushToStore")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <HeartOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 3</span>
                            <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right ml-auto">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
                              </svg>
                            </span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.valueServices")}</div>
                        </div>
                        <div class="products-main-flow-step" data-index="0">
                          <div class="products-main-flow-step-indicator mb-10">
                            <span role="img" class="anticon products-main-flow-step-indicator-icon">
                              <GiftOutlined style={{ fontSize: 14 }} />
                            </span>
                            <span class="text-12">{t("productIndex.steps.step")} 4</span>
                          </div>
                          <div class="products-main-flow-step-title mb-5">{t("productIndex.steps.globalShipping")}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="products-main-hero-banner-right">
                  <ProductBestSellers />
                  {/* <section className="products-main-box products-main-transaction-record-thumbnail h-[220px]">
                    <header className="products-main-box-header">
                      <h5 className="products-main-box-title">Featured List</h5>
                    </header>
                    <div className="products-main-box-body"></div>
                  </section> */}
                </div>
              </div>
            </div>
          </div>
          <div className="products-main-content">
            <div className="products-main-content-inner">
              {
                !searchParams && <ProductsFeaturedHotSection />
              }
              {
                searchParams && <ProductsSearchSection searchParams={searchParams} onReturn={() => setSearchParams(null)} />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}