import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const PushStatus = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING':
        return '待推送';
      case 'SUCCEED':
        return '已推送';
      case 'FAILED':
        return '推送失败';
      default:
        return '未知状态';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PushStatus;