import React from 'react';
import './CostEstimation.less';
import { useTranslation } from 'react-i18next';

const CostEstimation = () => {
  const { t } = useTranslation();
  return (
    <div className="sidebar">
      <section className="lite-card mb-16">
        <header className="lite-card-header">
          <h3 className="lite-card-title">{t("productDetail.costEstimate.title")}</h3>
        </header>
        <div className="lite-card-body">
          <p className="text-12 text-gray-dark">{t("productDetail.costEstimate.desc")}</p>
          <div className="d-flex mt-16">
            <div className="flex-center-vertical basis-3by5">
              <span className="d-inline-block truncate">{t("productDetail.costEstimate.productPrice")}</span>
              <span role="img" aria-label="question-circle" className="anticon anticon-question-circle ml-5" style={{ color: 'rgb(153, 153, 153)' }}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                </svg>
              </span>
            </div>
            <div className="basis-2by5 text-right">{t('common.currency.shortCode')} {t('common.currency.symbol')}-</div>
          </div>
          <div className="d-flex mt-16">
            <div className="flex-center-vertical basis-3by5">
              <span className="d-inline-block truncate">{t("productDetail.costEstimate.domesticShipping")}</span>
              <span role="img" aria-label="question-circle" className="anticon anticon-question-circle ml-5" style={{ color: 'rgb(153, 153, 153)' }}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                </svg>
              </span>
            </div>
            <div className="basis-2by5 text-right">{t('common.currency.shortCode')} {t('common.currency.symbol')}-</div>
          </div>
          <div className="d-flex mt-16">
            <div className="flex-center-vertical basis-3by5">
              <span className="d-inline-block truncate">{t("productDetail.costEstimate.internationalShipping")}</span>
              <span role="img" aria-label="question-circle" className="anticon anticon-question-circle ml-5" style={{ color: 'rgb(153, 153, 153)' }}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                </svg>
              </span>
            </div>
            <div className="basis-2by5 text-right">
              <span>{t('common.currency.shortCode')} {t('common.currency.symbol')}-</span>
            </div>
          </div>
          <div className="d-flex mt-16">
            <div className="flex-center-vertical basis-3by5">
              <span className="d-inline-block truncate">{t("productDetail.costEstimate.platformService")}</span>
              <span role="img" aria-label="question-circle" className="anticon anticon-question-circle ml-5" style={{ color: 'rgb(153, 153, 153)' }}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                </svg>
              </span>
            </div>
            <div className="basis-2by5 text-right">
              <span>{t('common.currency.shortCode')} {t('common.currency.symbol')}-</span>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="lite-card mb-16">
        <header className="lite-card-header">
          <h3 className="lite-card-title">国际运费预估</h3>
        </header>
        <div className="lite-card-body">
          <p className="text-12 text-gray-dark mb-16">请选择商品的重量和体积，以估算运费。</p>
          <fieldset className="mb-16">
            <div className="mb-16">
              <label>重量（g）</label>
              <input type="text" className="input-120" />
            </div>
            <div className="mb-16">
              <label>尺寸（cm）</label>
              <div className="d-flex">
                <input type="text" className="input-120 mr-16" placeholder="长" />
                <input type="text" className="input-120 mr-16" placeholder="宽" />
                <input type="text" className="input-120" placeholder="高" />
              </div>
            </div>
          </fieldset>
        </div>
      </section> */}
    </div>
  );
};

export default CostEstimation;
