import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';


const api = create({ baseURL: ''})

class ListingProductApi {

  async getListingProducts(params) {
    return await api.get(`/api/v1/listing_products?${qs.stringify(params)}`);
  }

  async addListingProductsByOfferId(params) {
    return await api.post(`/api/v1/listing_products/offer_id`, params);
  }
}

const listingProductApi = new ListingProductApi();

export default listingProductApi;