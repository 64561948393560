import React, { useState } from 'react';
import { Tag } from 'antd'; // Assuming you're using Ant Design for the Tag component
import { trans } from '../../util/transobj';

const SkuSelector = ({ skuData, onSkuSelected }) => {
  const [selectedValues, setSelectedValues] = useState({});

  const handleAttributeValueClick = (attributeName, attributeValue) => {
    setSelectedValues(prevSelectedValues => ({
      ...prevSelectedValues,
      [attributeName]: attributeValue,
    }));

    // // Check if all attributes are selected
    // setTimeout(() => {
    //   const allAttributesSelected = Object.keys(selectedValues).length === skuData.skuAttrs.length;
    //   if (allAttributesSelected) {
    //     // Construct the key based on selected values to find skuId in skuIdsMap
    //     const selectedKey = skuData.skuAttrs.map(attr => selectedValues[attr.attributeName]).join('__');
    //     const skuId = skuData.skuIdsMap[selectedKey];
    //     if (skuId) {
    //       onSkuSelected(skuId);
    //     }
    //   }
    // }, 0)

  };

    // Effect to handle logic when selectedValues changes
    React.useEffect(() => {
      const allAttributesSelected = Object.keys(selectedValues).length === skuData?.skuAttrs.length;
  
      if (allAttributesSelected) {
        // Construct the key based on selected values to find skuId in skuIdsMap
        const selectedKey = skuData?.skuAttrs.map(attr => selectedValues[attr.attributeName]).join('__');
        const skuId = skuData?.skuIdsMap[selectedKey];
        if (skuId) {
          onSkuSelected(skuId);
        }
      }
    }, [selectedValues, skuData?.skuAttrs, skuData?.skuIdsMap, onSkuSelected]);

  return (
    <div className="sku-list-cont-default">
      {skuData?.skuAttrs?.map(skuAttr => (
        <div className="sku-list" key={skuAttr.attributeName}>
          <div className="text-black mb-8">{trans(skuAttr, 'attributeNameTrans', 'attributeName')}:</div>
          <div className="mb-16">
            {skuAttr.attributeValues.map(skuAttrValue => (
              <Tag
                key={skuAttrValue.valueTrans}
                style={{
                  marginBottom: 8,
                  cursor: 'pointer',
                  background: selectedValues[skuAttr.attributeName] === skuAttrValue.value ? '#1890ff' : '#f0f2f5',
                  color: selectedValues[skuAttr.attributeName] === skuAttrValue.value ? '#ffffff' : '#000000',
                }}
                // onClick={() => handleAttributeValueClick(skuAttr.attributeName, skuAttrValue.value)}
              >
                <span className="tag-content">{trans(skuAttrValue, 'valueTrans', 'value')}</span>
              </Tag>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkuSelector;