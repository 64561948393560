import React from 'react';
import DesktopIndex from './DesktopIndex';
import MobileIndex from './MobileIndex';
import './Index.less';

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iPad|iPhone|iPod/i.test(userAgent);
};


export default () => {

  return (
    <>
    {
      isMobile() ? <MobileIndex /> : <DesktopIndex />
    }
    </>
  )
}