import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';

const api = create({ baseURL: ''})

class ShopApi {

  async getShops(params) {
    return await api.get(`/api/v1/shops?${qs.stringify(params)}`);
  }

  async getShopList() {
    return await api.get(`/api/v1/shops_list`);
  }

  async getShopProducts(id, params) {
    return await api.get(`/api/v1/shops/${id}/shop_products?${qs.stringify(params)}`);
  }

  async pushProductsToShop(id, params) {
    return await api.post(`/api/v1/shops/${id}/shop_products/push`, params);
  }

  async addShopProducts(id, params) {
    return api.post(`/api/v1/shops/${id}/shop_products`, params);
  }

  async addProductsToShops(params) {
    return api.post(`/api/v1/shops/shop_products`, params);
  }

  async confirmSaleOrder(id, saleOrderId) {
    return api.post(`/api/v1/shops/${id}/sale_orders/${saleOrderId}/confirm`);
  }

  async startAuth(shopUrl) {
    return api.get(`/api/v1/shops/start-auth?shopUrl=${encodeURIComponent(shopUrl)}`)
  }
}

const shopApi = new ShopApi();
export default shopApi;