import React from 'react';
import './ProductDetail.less';
import { Breadcrumb } from 'antd';
import ProductDetailCard from './component/ProductDetailCard';
import CostEstimation from './component/CostEstimation';
import ProductDetailDesc from './component/ProductDetailDesc';
import productApi from '../servapi/product';
import Dashboard from './Dashboard';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trans } from '../util/transobj';

export default props => {
  const { t, i18n } = useTranslation();
  const [productDetail, setProductDetail] = React.useState({});
  let { offerId } = useParams();

  React.useEffect(() => {
    productApi.getProductDetail({
      // "offerId": 788973112079,
      "offerId": parseInt(offerId),
      "country": i18n.language
    })
    .then(setProductDetail)
    .catch(console.log);
  }, [offerId, i18n.language])

  return (
    <>
      <div className="page_head">
        <div id="page_head_content">
          <div className="page_breadcrumb pt-12">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/dashboard">
                  <span className="cursor-pointer">{t('productDetail.breadcrumb.selection')}</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{t('productDetail.breadcrumb.detail')}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="product_title truncate">
            <h3 className='m-0 leading-18 h-18 px-2 text-24 flex-grow h-[72px]' style={{ lineHeight: '72px'}}>{trans(productDetail, 'subjectTrans', 'subject')}</h3>
          </div>
        </div>
      </div>
      <div className="inner-content relative px-[16px]" style={{ paddingTop: 132 }}>
        <div className="common-detail-container">
          <div className="d-flex">
            <div className="flex-grow-1">
              <ProductDetailCard productDetail={productDetail}/>
              <ProductDetailDesc productDetail={productDetail}/>
            </div>
            <div className="ml-16 flex-shrink-0 w-[300px]">
              <CostEstimation />
            </div>
          </div>
        </div>
      </div>
    </>

  )
}