import React from 'react';
import { Card, Row, Col, Typography, Tag, Button, Space, Divider, Image } from 'antd';
import './ProductDetailDesc.less';
import { useTranslation } from 'react-i18next';

const IframeHtmlString = ({ htmlString }) => {
  const iframeRef = React.useRef(null);
  // const htmlString = '<div><p>Injected HTML</p></div>';

  React.useEffect(() => {
    if (!htmlString) {
      return;
    }
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        iframeDocument.body.innerHTML = htmlString;
        resizeIframe()
      }
    }
  }, [htmlString]);

  function resizeIframe() {
    if (iframeRef.current) {
      iframeRef.current.style.height = `${iframeRef.current.contentWindow.document.body.height}px`;
    }
  }

  return (
    <div>
      {/* Your React components */}
      <iframe title="Isolated Content" style={{ width: '100%', border: 0, overflow: 'hidden'}}  scrolling="no" ref={iframeRef}/>
    </div>
  );
};


export default ({ productDetail }) => {
  const { t } = useTranslation();
  
  return (
    <Card title={t('productDetail.detail.title')} className="mb-16">
      <div className="goods-product-detail">
        {/* <IframeHtmlString htmlString={productDetail?.description}/> */}
        <div dangerouslySetInnerHTML={{ __html: productDetail?.description }} />
        {/* <p>
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN016FgpX82EQW9AY60Zc_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01y2ng2g2EQW9BgydQR_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01GXGFVl2EQW9CKsjvR_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01FNFjL02EQW97BqpxA_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01L9kjxV2EQW8zKkLkh_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01xMZzPl2EQW982znRL_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01iL1lke2EQW97wum12_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01cDCXEQ2EQW99fZvRe_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01xzoAS82EQW99RUQwE_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN011JENcA2EQW94uWqUb_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01bBcv7K2EQW95NPWXi_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01mpFV7a2EQW99RTQYq_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01lem6eC2EQW97BxLNl_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01SUdCIt2EQW9Awmg0J_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01Dku69B2EQW9CKsGrP_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01TkzGYv2EQW98x7qi7_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01X5KTss2EQW97BvKe8_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01gwCFJ02EQW93tzQRx_!!2214378058739-0-cib.jpg" />
          <img src="https://cbu01.alicdn.com/img/ibank/O1CN01m6GFWg2EQW97wweSn_!!2214378058739-0-cib.jpg" />
        </p> */}
      </div>
    </Card>
  )

}