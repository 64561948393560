import { makeAutoObservable } from "mobx";
import authApi from "../servapi/auth";

class UserStore {
  userInfo = {};

  constructor() {
    makeAutoObservable(this);
  }

  async loadUserInfo() {
    if (this.userInfo.username) {
      return this.userInfo;
    }
    
    const userInfo = await authApi.getUserInfo();
    this.userInfo.username = userInfo.username;
    this.userInfo.active = userInfo.active;

    return this.userInfo;
  }

  async reset() {
    this.userInfo = {};
  }
}

export default UserStore;