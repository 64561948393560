import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Radio, Checkbox } from 'antd';
import shopApi from '../../servapi/shop';

const ShopListOverlay = ({ visible, onCancel, onConfirm }) => {
  const [shops, setShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  useEffect(() => {
    shopApi.getShopList()
      .then(setShops)
      .catch(console.log);
  }, []);

  const handleConfirm = () => {
    console.log(selectedShops)

    onConfirm && onConfirm(selectedShops);
  }


  return (
    <Modal
      title="加入到我的店铺"
      visible={visible}
      cancelText="取消"
      okText="确认"
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <Form layout="vertical" className="hide-required-mark">
        <div style={{ display: 'block' }}>
          <Row style={{ rowGap: '0px' }}>
            <Col span={24}>
              <Form.Item label="">
                <Checkbox>
                  <span>全选</span>
                  <span>(<span className="text-red">{selectedShops.length}</span> Store(s) Selected)</span>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Checkbox.Group id="shopCodes" style={{ display: 'inline-grid' }} onChange={values => setSelectedShops(values)}>
                  {
                    shops.map(shop => (
                      <Checkbox value={shop.id} className="flex-center-vertical mb-12">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ height: '28px', width: '28px', display: 'inline-block' }}>
                            <img src="" height="100%" width="100%" alt="" />
                          </div>
                          <span style={{ marginLeft: '8px', fontSize: '16px' }}>{shop.shopName} - {shop.shopUrl}</span>
                        </div>
                      </Checkbox>
                    ))
                  }
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ShopListOverlay;
