import React, { useState, useCallback } from 'react';
import { Form, Input, Select, Button, Checkbox, Row, Col, Carousel } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import './RegisterForm.less';
import { Link, useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { inject  } from 'mobx-react';
import authApi from '../../servapi/auth';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const RegisterForm = ({}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [emailExist, setEmailExist] = React.useState(false); 
  const [passwordValid, setPasswordValid] = React.useState(true); // State to track password validation
  const [agreementChecked, setAgreementChecked] = React.useState(false); 
  const [captchaSent, setCaptchaSent] = React.useState(false); 
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [formValid, setFormValid] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    console.log('Received values:', values);

    try {
      const result = await authApi.register(values);

      const { token } = await authApi.login({
        username: values.email,
        password:  values.password
      })

      cookie.set('token', token);

       navigate({
        pathname: '/dashboard',
      })
    } catch(error) {
      console.error('Error register:', error);
    }

  };

  // Validate email format on change
  const validateEmailFormat = (e) => {
    const value = e.target.value;
    if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  // Validate email existence on blur
  const validateEmailExistence = async (e) => {
    const value = e.target.value;
    try {
      const response = await authApi.checkEmail({ email:value});
      // const data = await response.json();
      if (response.exists) {
        setEmailError('Email already exists');
      } else {
        setEmailError('');
      }
    } catch (error) {
      setEmailError('Failed to validate email');
    }
  };

  // Validate password on change
  const validatePassword = (e) => {
    const value = e.target.value;
    if (value && !(value.length >= 8 && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value))) {
      setPasswordError('Password must be at least 8 characters long and contain letters and numbers');
    } else {
      setPasswordError('');
    }
  };

  // Validate captcha on change
  const validateCaptcha = (e) => {
    const value = e.target.value;
    if (value && !/^\d{4}$/.test(value)) {
      setCaptchaError('Please enter a 4-digit captcha');
    } else {
      setCaptchaError('');
    }
  };

  // Enable register button when all fields are valid and agreement is checked
  React.useEffect(() => {
    validForm();
  }, [emailError, passwordError]);

  const validForm = () => {
    try {
      if (!emailError && !passwordError && form.getFieldValue('agreement') && form.getFieldValue('email') && form.getFieldValue('password')) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    } catch (error) {
      setFormValid(false);
    }
  }

  return (
    <div className="register-banner-row flex justify-center" style={{ height: '660px'}}>
      <div className="register-box">
        <div className="flex-center-vertical justify-center mb-40 mt-60">
          {/* <h3 className='text-32 text-bold'>PINTABACK</h3> */}
          <Link to="/">
            <img src="/images/logo_t.png" alt="" style={{ width: '300px'}} />
          </Link>
          {/* <a href="/cn"><img className="logo" src="https://cdn.buckydrop.com/starit-buckydrop/2024/dist/logo_with_name_black.f5e9907a.png" alt="BuckyDrop" lang="cn" height="67" /></a> */}
        </div>
        <Form
          form={form}
          className="register-form ant-form ant-form-horizontal"
          onFinish={onFinish}
          // onValuesChange={values => setTimeout(() => validateAllFields(values), 500)}
          initialValues={{ agreement: false }}
          // validateMessages={validateMessages}
        >
          <Form.Item
            className="ant-row ant-form-item"
            name="email"
            style={{ rowGap: '0px' }}
            validateStatus={emailError ? 'error' : ''}
            help={emailError || undefined}
            rules={[
              { required: true, message: 'Please enter your email' },
              // { validator: validateEmailExistence }
            ]}
          >
            <Input size="large" placeholder={t("register.email")} onChange={validateEmailFormat} onBlur={validateEmailExistence}/>
          </Form.Item>
          <Form.Item
            className="ant-row ant-form-item"
            name="password"
            style={{ rowGap: '0px' }}
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError || undefined}
            rules={[
              { required: true, message: 'Please enter your password' },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("register.password")}
              iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <></>)}
              onChange={validatePassword}
            />
          </Form.Item>
          {/* <Form.Item
            className="ant-row ant-form-item verification-code-form-item"
            name="verificationCode"
            style={{ rowGap: '0px' }}
            validateStatus={captchaError ? 'error' : ''}
            help={captchaError || undefined}
            rules={[
              { required: true, message: 'Please enter the captcha' },
            ]}
          >
            <Input size="large" placeholder={t("register.verifyCode")} onChange={validateCaptcha}/>
          </Form.Item> */}
          {/* <Form.Item
            className="ant-row ant-form-item phone-form-item shame-single-error-info"
            name="cellphone"
            style={{ rowGap: '0px' }}
          >
            <Select
              size="large"
              showSearch
              placeholder="区号"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="86">+86</Option>
              <Option value="1">+1</Option>
            </Select>
            <Input size="large" placeholder="请输入您的手机号码" />
          </Form.Item> */}
          <Form.Item
            className="ant-row ant-form-item promotion-code"
            name="invitationCode"
            style={{ rowGap: '0px' }}
          >
            <Input size="large" placeholder={t("register.invitationCode")} />
          </Form.Item>
          <Form.Item
            className="ant-row ant-form-item"
            name="agreement"
            valuePropName="checked"
            style={{ rowGap: '0px' }}
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please agree to terms') }]}
          >
            <Checkbox onChange={() => validForm()}>
              {t('register.hadRead')} <a href="" target="_blank">{t("register.aggrement")}</a> {t("register.and")} <a href="/cn/privacy/" target="_blank">{t("register.policy")}</a>
            </Checkbox>
          </Form.Item>
          <Form.Item
            className="ant-row ant-form-item"
            style={{ rowGap: '0px' }}
          >
            <Button type="primary" htmlType="submit" size="large" disabled={!formValid}>
              {t("register.registerBtn")}
            </Button>
          </Form.Item>
        </Form>
        <div className="goto-login">
          {t("register.hadAccount")}？ <Link to="/login"><span>{t("register.login")}</span></Link>
        </div>
      </div>
      {/* <div className="banner-box">
        <Carousel autoplay>
          <div>
            <img className="banner-box-image" src="https://bd-images-prod-1252252286.cos.accelerate.myqcloud.com/root_c3c9efd60f7f7fb16f57fd7a74bd71c3/my-images/2024/04/23/17138600682990.png" alt="Banner 1" />
          </div>
          <div>
            <img className="banner-box-image" src="https://bd-images-prod-1252252286.cos.accelerate.myqcloud.com/root_c3c9efd60f7f7fb16f57fd7a74bd71c3/my-images/2024/04/23/17138601404020.png" alt="Banner 2" />
          </div>
          <div>
            <img className="banner-box-image" src="https://support-cdn.buckydrop.com/root_2933ebfb63209b6c21e51dce84f1374e/my-images/2022/04/27/1651054771947n.jpg" alt="Banner 3" />
          </div>
        </Carousel>
      </div> */}
    </div>
  );
};

export default RegisterForm;
