import React, { useEffect } from 'react';
import Head from '../layout/Head';
import SearchInput from '../component/SearchInput';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Form, Input, Button, Modal } from 'antd';
import 'react-tabs/style/react-tabs.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import productApi from '../servapi/product';
import authApi from '../servapi/auth';
import cookie from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { inject } from 'mobx-react';
import { FireOutlined, CrownOutlined, StarOutlined, LikeOutlined, DollarOutlined, TagOutlined } from '@ant-design/icons';
import './DesktopIndex.less';
import { trans } from '../util/transobj';

function formatNumber(num) {
  if (num >= 10000) {
    return (num / 10000).toFixed(1) + 'w+';
  } else if (num >= 1000) {
    return Math.floor(num / 1000) + 'k+';
  } else {
    return num.toString();
  }
}

// super_factory-超级工厂 powerful_merchants-实力商家 tp_member-诚信通会员


const Product = ({ product, idx }) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, idx * 150);
  }, []);

  const tags = {
    'super_factory': t('index.search.product.tags.superFactory'),
    'powerful_merchants':  t('index.search.product.tags.powerfulMerchants'),
    // 'tp_member': '诚信通会员',
    'yxmj':  t('index.search.product.tags.yxmj'),
  }
  
  function translateProductTag(identity) {
    return tags[identity];
  }

  const productTags = (product.sellerIdentities || []).map(identity => translateProductTag(identity)).filter(tag => tag != null).slice(0,3);
  return (
    <Link to={`/product/detail/${product.offerId}`} target='_blank'>
      <div className="product-banner flex-col bg-white w-[220px] h-[376px] cursor-pointer mb-8" style={{ marginLeft: '-73px', marginRight: '10px', marginLeft: '0', marginBottom: '8px', ...(visible ? { opacity: 1, transform: 'scale(1)'} : {}) }}>
        <div className="flex-col justify-between w-[220px] h-[272px]">
          <div className="flex-col bg-cover w-[220px] h-[220px]" style={{ backgroundImage: `url(${product.imageUrl})` }}></div>
          {/* <div className="flex-col bg-cover w-[220px] h-[220px]" style={{ backgroundImage: 'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/76fc3c44cd514810aed5ceb5fb12ffd0_mergeImage.png)' }}></div> */}
          <span className="text-black  font-normal h-[36px] ml-[18px] mt-[16px] w-[192px] line-clamp-2 whitespace-pre-line text-sm text-ellipsis">{trans(product, 'subjectTrans', 'subject')}</span>
        </div>
        <div className="mt-[8px] ml-[16px] flex min-h-[18px]" >
          {
            productTags && productTags.length > 0 ? productTags.map(tag => (
              <span className="px-[2px] border border-orange-500 overflow-hidden text-orange-500 text-xs font-normal whitespace-nowrap mr-[4px]">{tag}</span>
            )) : (
              <span className="px-[2px] border border-orange-500 overflow-hidden text-orange-500 text-xs font-normal whitespace-nowrap mr-[4px]">{tags['yxmj']}</span>
            )
          }
        </div>
        <div className="flex-row mt-[7px] ml-[18px] justify-between">
          <span className=" text-black text-lg font-normal whitespace-nowrap">{t("common.currency.symbol")}{product.priceInfo.price}</span>
          <div className="flex items-center mr-[16px]">
            <div className="font-normal whitespace-nowrap">
              <span className=" text-black text-xs">Rr</span>
              <span className=" text-black text-xs">:</span>
              <span className=" text-black text-xs">45%</span>
            </div>
            <img className=" h-[8px] ml-[7px] mt-[10px]" referrerPolicy="no-referrer" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng965299bffe139dd9ae1b0451c1e9ff2e970e65c2feedd5c9b924b6ec13f66bba" />
            <div className="font-normal whitespace-nowrap ml-[8px]">
              <span className=" text-black text-xs">DSR</span>
              <span className=" text-black text-xs">:</span>
              <span className=" text-black text-xs">4.5</span>
            </div>
          </div>
        </div>
        <span className=" overflow-hidden text-gray-600 text-xs font-normal whitespace-nowrap ml-[18px] mt-[1px]">Sold&nbsp;{formatNumber(product.monthSold)}&nbsp;pairs&nbsp;in&nbsp;30&nbsp;days</span>
        <div className="flex">
          <span className=" block w-[16px] h-[16px] ml-[18px]"></span>
          <span className="block w-[16px] h-[16px]"></span>
        </div>
      </div>
    </Link>
  )
}
const langParams = {
  'zh-CN': 'cn',
  'ru-RU': 'ru',
  'en-US': 'en',
  'es-ES': 'es',
}

const RankHot = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '1036995',
      rankType: 'hot',
      limit: 9,
      language: langParams[i18n.language],
    })
    .then(({ rankProductModels = []} ) => setData(rankProductModels))
    .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const RankComplex = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '126506005',
      rankType: 'complex',
      limit: 9,
      language: langParams[i18n.language],
    })
    .then(({ rankProductModels = [] } ) => setData(rankProductModels))
    .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const RankPrice = () => {
  const { i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    productApi.getRank({
      rankId: '1032118',
      rankType: 'goodPrice',
      limit: 9,
      language: langParams[i18n.language],
    })
    .then(({ rankProductModels = [] } ) => setData(rankProductModels))
    .catch(console.log);
  }, [i18n.language]);

  return (
    <SlideWindow cards={data} />
  )
}

const TabSwitcher = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = React.useState(0);

  return (
    <Tabs selectedIndex={current} onSelect={setCurrent}>
      <TabList className="flex justify-between mb-[24px]">
        <span className="font-alibabasans font-medium text-3xl text-blue-600 leading-14 text-left">{t("index.discover.title")}</span>
        <div className="flex">
          <Tab className="">
            <div className="h-[72px]  flex-row cursor-pointer items-center">
              <div className="h-[25px]  flex-row justify-between px-[18px]">
                <span  className="w-[18px] h-[18px] mt-[1px] mr-[8px]">
                  <StarOutlined style={{ fontSize: '18px'}}/>
                </span>
                <span className="h-[25px] break-words text-md font-medium">{t("index.discover.tabs.top")}</span>
              </div>
            </div>
          </Tab>
          <Tab className="">
            <div className="h-[72px]  flex-row cursor-pointer items-center">
              <div className="h-[25px] flex-row justify-between px-[18px]">
                <span  className="w-[18px] h-[18px] mt-[1px] mr-[8px]">
                  <FireOutlined style={{ fontSize: '18px'}}/>
                </span>
                <span className="h-[25px] break-words text-md font-medium">{t("index.discover.tabs.hot")}</span>
              </div>
            </div>
          </Tab>
          <Tab className="">
            <div className="h-[72px]  flex-row cursor-pointer items-center">
              <div className="h-[25px] flex-row justify-between px-[18px]">
                <span  className="w-[18px] h-[18px] mt-[1px] mr-[8px]">
                  <TagOutlined style={{ fontSize: '18px'}}/>
                </span>
                <span className="h-[25px] break-words  text-md font-medium">{t("index.discover.tabs.price")}</span>
              </div>
            </div>
          </Tab>
        </div>
      </TabList>
      <TabPanel>
        <RankComplex />
      </TabPanel>
      <TabPanel>
        <RankHot />
      </TabPanel>
      <TabPanel>
        <RankPrice />
      </TabPanel>
    </Tabs>
  )
}

const SlideWindow = ({ cards }) => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };


  return (
    <Slider {...settings} style={{ width: '100%', minHeight: '506px' }} >
      {cards.map((card, index) => (
        <div className=''>
          <div key={index} className="bg-white w-[364px] h-[506px] flex-col mr-[12px]">
            {/* <div className="w-[364px] h-[364px] bg-[url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fee8409d5737498eb5f20b72cddd95c3_mergeImage.png)] flex-col" /> */}
            <div className={`w-[364px] h-[364px] flex-col`} style={{ backgroundImage: `url(${card.imgUrl})`, backgroundSize: 'cover' }} />
            <div className={`w-[43px] h-[37px] bg-no-repeat bg-cover ml-[16px] flex-row justify-center`} 
                style={{ backgroundImage: `url(${window.location.origin}/images/Sketch-Png6dced845a8ee89be3007ac90a7f44e19fb724746dfc0fe9768a19151061af798.png)`}}>
              <div className="text-relative w-[22px] h-[37px] flex-col">
                <span className="break-words text-white text-xs font-bold text-left whitespace-nowrap leading-[16px] ml-[2px]">Top</span>
                <span className="break-words text-white font-bold text-left whitespace-nowrap flex justify-center">{index < 9 ? `0${index + 1}` : index + 1}</span>
              </div>
            </div>
            <div className="w-[332px] break-words text-black text-xs font-normal text-left leading-[18px] ml-[15px] mt-[9px] line-clamp-2">
              {trans(card, 'translateTitle', 'title')}
            </div>
            <div className="w-[331px] h-[28px] ml-[16px] mt-[12px] mb-[20px] flex-row justify-between">
              <span className="w-[37px] h-[28px] break-words text-blue-500 text-base font-normal text-left whitespace-nowrap leading-[28px]">{t('common.currency.symbol')}{card.priceInfo.price}</span>
              <div className='flex items-center'>
                <span className="h-[18px] break-words text-gray-600 text-xs font-normal text-center whitespace-nowrap leading-[18px] mt-[5px]">Sold&nbsp;{card.soldOut}&nbsp;pairs&nbsp;in&nbsp;30&nbsp;days</span>
                <img
                  className="w-[1px] h-[8px] mt-[10px] ml-[4px]"
                  src={
                    "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9ef093edb63e5987ac31b152cff92fd37093c6058e3f7c301ff2d0c610542645"
                  }
                />
                <span className="h-[18px] break-words text-black text-xs font-normal text-left whitespace-nowrap leading-[18px] ml-[7px] mt-[5px]">DSR:4.5</span>
              </div>

            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex rounded-full justify-center items-center opacity-15 hover:opacity-100`}
      style={{ ...style, background: '#0055CC', width: 40, height: 40, display: 'flex', right: '-50px', }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex rounded-full justify-center items-center  opacity-15 hover:opacity-100`}
      style={{ ...style, background: '#0055CC', width: 40, height: 40, display: 'flex', left: '-50px', }}
      onClick={onClick}
    />
  );
};


const SearchSection = ({ onSearchComplete }) => {
  const { t, i18n } = useTranslation()
  const [pageData, setPageData] = React.useState({});
  const [effect, setEffect] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState({});

  const handleSearch = keyword => {
    setSearchParams({
      ...searchParams,
      keyword,
      imageId: null,
    });
  }

  const handleSearchImage = ({ imageId }) => {
    if (!imageId) {
      return;
    }

    setSearchParams({
      ...searchParams,
      imageId,
      keyword: null,
    })
  }

  useEffect(() => {
    if (!searchParams.keyword && !searchParams.imageId) {
      return;
    }

    setEffect(true);
    if (searchParams.imageId) {
      productApi.searchImage({
        "beginPage": 0,
        "country": i18n.lanugage,
        // "categoryId": 1031861,
        "pageSize": 10,
        "imageId": searchParams.imageId,
        // "imageId": "1019208131918790770",
        // ...searchParams,
      })
        .then(setPageData)
    } else {
      productApi.search({
        "beginPage": 0,
        "country": i18n.lanugage,
        "pageSize": 10,
        ...searchParams,
      })
        .then(setPageData)
    }
  }, [searchParams, i18n.language])

  return (
    <>
      <div className={`${effect ? 'floatup-effect' : ''}`}>
        <div className="flex-col mt-[230px]">
          {/* <span className="break-words text-white text-[36px] font-AlibabaSans font-bold text-left whitespace-nowrap leading-[56px]"
          > Instantly&nbsp;search&nbsp;for&nbsp;the&nbsp;products&nbsp;you&nbsp;want.</span> */}
          <span className="break-words text-white text-[36px] font-AlibabaSans font-bold text-center whitespace-nowrap leading-[56px]"
          >{t("index.search.title")}</span>
        </div>
        <SearchInput onSearch={handleSearch} onSearchImageUpload={handleSearchImage}/>
      </div>
      {
        pageData && pageData.data && (
          <div className="flex flex-wrap w-[1150px] mx-[388px] mt-[24px] relative " style={{ top: '-100px'}}>
            {
              pageData.data.map((product, idx) => <Product key={product.offerId} product={product} idx={idx} />)
            }
          </div>
        )
      }
    </>
  )
}


export default inject('userStore')(({ userStore }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    const load = async () => {
      try {
        await userStore.loadUserInfo();
      } catch(e) {
      }
    }

    load();
  }, [])

  return (
    <div className="page flex-col">
      <div className="wrap">
        <Head/>
        <div className="min-h-[680px] bg-no-repeat" style={{ backgroundImage: 'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e0625e36a25f4895b1ca8d11ee1eea09_mergeImage.png)', backgroundPosition: 'top center', backgroundSize: '100% auto'}}>
          <div className="container flex-col align-items-center">
            <SearchSection />
          </div>
        </div>
        <div className="box_33 flex-col">
          <div className="block_5 flex-col">
            <div className="container flex-col pt-[72px]">
              <TabSwitcher />
            </div>
          </div>
          <div className="block_15 flex-col" style={{ background: '#0055CC'}}>
            <div className="container flex-col">
              <span className="break-words text-white text-[36px] font-AlibabaSans-Medium font-semibold text-left whitespace-nowrap mt-[64px] flex justify-center"
              >{t("index.solutions.title")}</span>
              <div className="list_4 flex-row">
                <div className="w-[490px]">
                  <img className="h-[250px] w-[490px] object-cover" src="/images/agence-olloweb-qfp4-Ud6-Fyg-unsplash.jpg" />
                  <div className="text-group_25-0 flex-col">
                    <h5 className="overflow-wrap break-words text-white text-center text-[20px] font-AlibabaSans-Medium font-semibold whitespace-nowrap mb-[16px]"
                    >{t("index.solutions.b2b.title")}</h5>
                    <div className="flex-col">
                      {
                        [0,1,2,3,4].map(idx => (
                          <p className="overflow-wrap break-words text-white text-left text-[16px] font-AlibabaSans-Regular font-normal leading-[25px]"
                          >{t(`index.solutions.b2b.b2b-c${idx}`)}</p>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className="w-[490px]">
                  <img className="h-[250px] w-[490px] bg-no-repeat bg-cover" src="/images/jacques-dillies-jcav1-COVv-Oc-unsplash.jpg" />
                  <div className="text-group_25-1 flex-col">
                    <h5 className="overflow-wrap break-words text-white text-center text-[20px] font-AlibabaSans-Medium font-semibold whitespace-nowrap ">{t("index.solutions.supply.title")}</h5>
                    <span className="overflow-wrap break-words text-white text-left text-[16px] font-AlibabaSans-Regular font-normal leading-[25px] mt-[16px]"
                    >{t("index.solutions.supply.content")}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="flex-row justify-between pt-[100px] pb-[100px]" style={{ background: '#fff' }}>
            <div className="container flex-row">
              <img
                className="w-[351px] h-[351px] mr-[97px]"
                referrerpolicy="no-referrer"
                src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae7465d603d84a36a63fafe299fb4ee6_mergeImage.png"
              />
              <div className="flex-col w-[608px]">
                <span className="font-AlibabaSans text-[36px] leading-[56px] text-left break-words font-bold" style={{ color: '#004CBB' }}
                >{t("index.overseas.title")}</span>
                <div className="flex-col">
                  <p className="font-AlibabaSans text-[16px] leading-[25px] text-left break-words mt-[24px]" style={{ color: '#333' }}
                  >{t("index.overseas.c0")}</p>
                  <p className="font-AlibabaSans text-[16px] leading-[25px] text-left break-words mt-[12px]" style={{ color: '#333' }}
                  >{t("index.overseas.c1")}</p>
                </div>
        
                <div className="flex-row mt-[24px]">
                  <div className="image-text_19 flex-row justify-between">
                    <img
                      className="label_8"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6666c43581fc060996e2220b37e8ec011edaf53709094ebf40630b293a50473e"
                    />
                    <span className="font-AlibabaSans text-[20px] leading-[31px] text-left font-bold" style={{ color: '#004CBB' }}>{t("index.overseas.contanct")}:</span>
                  </div>
                  <span className="font-AlibabaSans text-[20px] leading-[31px] text-left font-bold ml-[8px]" style={{ color: '#004CBB' }}>hello@pintaback.com</span>
                </div>
              </div>
            </div>

          </div>
          <div className="block_17 flex-col">
            <div className="container flex-col">
              <span className="font-AlibabaSans font-medium text-[36px] text-black leading-[56px] text-left mt-[64px] mb-[24px]">{t("index.case.title")}</span>
              <div className="flex-row">
                <div className="flex-col bg-white w-[558px] h-[552px] mr-[12px]" >
                  <div className="w-[558px] h-[310px] flex-col mb-[16px] bg-no-repeat bg-cover" style={{ backgroundImage: 'url(/images/campaign-creators-g-Msn-Xq-ILjp4-unsplash.jpg)' }}></div>
                  <div className="flex-row w-full items-center pl-[16px] pr-[16px]">
                    <div className="w-[54px] h-[54px] bg-gray-300 rounded-full flex-col justify-center items-center" style={{ background: '#0055CC' }}>
                      <span className="text_45 ml-[0px] mt-[0px]">USA</span>
                    </div>
                    <span className="font-AlibabaSans font-medium text-base text-black leading-31 ml-[12px]">{t("index.case.usa.title")}</span>
                  </div>
                  <span className="font-AlibabaSans text-base text-black leading-25 w-full break-words pl-[16px] pr-[16px] mt-[16px]"
                  >{t("index.case.usa.content")}</span>
                </div>
                <div className="flex-col bg-white w-[558px] h-[552px] ml-[12px]" >
                  <div className="w-[558px] h-[310px] bg-gray-600 flex-col mb-[16px] bg-no-repeat bg-cover" style={{ backgroundImage: 'url(/images/headway-5-Qg-Iuu-Bx-Kw-M-unsplash.jpg)' }}></div>
                  <div className="flex-row w-full items-center pl-[16px] pr-[16px]">
                    <div className="w-[54px] h-[54px] bg-gray-300 rounded-full flex-col justify-center items-center" style={{ background: '#0055CC' }}>
                      <span className="text_45 ml-[0px] mt-[0px]">Korea</span>
                    </div>
                    <span className="font-AlibabaSans font-medium text-base text-black leading-31 ml-[12px]">{t("index.case.korea.title")}</span>
                  </div>
                  <span className="font-AlibabaSans text-base text-black leading-25 w-full break-words pl-[16px] pr-[16px] mt-[16px]"
                  >{t("index.case.korea.content")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row pt-[48px] pb-[66px]" style={{ background: '#333' }}>
          <div className="container flex-row">
            <div className="w-1/3 flex-col">
              <span className="font-AlibabaSans font-medium text-white text-base leading-25 mb-[16px]">PINTABACK co.,Ltd</span>
              <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[8px]">{t("index.footer.menu.home")}</span>
              <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[8px]">{t("index.footer.menu.search")}</span>
              <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[8px]">{t("index.footer.menu.services")}</span>
              <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[8px]">{t("index.footer.menu.contact")}</span>
              <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[8px]">{t("index.footer.menu.case")}</span>
            </div>
            <div className="w-1/3 flex-col">
              <div className="flex-col justify-between">
                <span className="font-AlibabaSans font-medium text-white text-sm leading-25 text-left mb-[16px]">{t("index.footer.more.more")}</span>
                <span className="font-AlibabaSans text-base font-normal text-white leading-18 mb-[24px]">{t("index.footer.more.about")}</span>
              </div>
              <img
                className=""
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2145cf28f06a843c60b42a1130e74d4551d9844cf9ab5071ecafa96c5867f4cf"
              />
              <span className="font-AlibabaSans font-medium text-white text-base leading-25 text-left mt-[24px] mb-[12px]">{t("index.footer.more.follow")}</span>
              <div className="flex-row">
                <img
                  className="w-[20px] h-[20px]"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng99dc73de7f8c016e5b44378d0f1668b048f9a8f6cefa6c02e9287849cac22738"
                />
                <img
                  className="w-[20px] h-[20px]"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0ab8e9516bd7d7f6ec11e40acec26b7daeb613572aeb2dedc588badeb24cb9ba"
                />
                <div className="w-[20px] h-[20px] flex-col">
                  <div className="rounded-md bg-cover bg-center bg-no-repeat w-[16px] h-[16px] m-[2px] flex-col" style={{ backgroundImage: `url('https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/98ddc4d957364035b3d65a2518050cfe_mergeImage.png')` }}></div>
                </div>
              </div>
            </div>
            <div className="w-1/3 flex-col">
              <div className="w-[107px] h-[36px]flex-col justify-center items-center self-end">
                {/* <span className="font-medium text-white text-base leading-22">logo</span> */}
                <img src="/images/logo_w.png"/>
              </div>
              <span className="font-normal text-white text-sm leading-18 self-end mt-auto opacity-30" style={{ color: '#fff' }}
              >Copyright&nbsp;©&nbsp;2024&nbsp;PINTABACK&nbsp;All&nbsp;Rights&nbsp;Reserved</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})