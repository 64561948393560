import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const SupplierOrderStatus = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return '已创建';
      case 'PENDING_PAY':
        return '待支付';
      case 'PARTIAL_PAID':
          return '部分支付';
      case 'PAID':
        return '已支付';
      case 'COMPLETED':
        return '已完成';
      case 'CANCELLED':
        return '已取消';
      case 'SHIPPED':
        return '已发货';
      case 'RECEIVED':
        return '已收货';
      case 'REFUNDED':
        return '已退款';
      case 'RETURNED':
        return '已退货';
      case 'FAILED':
        return '已失败';
      default:
        return '未知状态';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default SupplierOrderStatus;