import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Button, Table, Tag, Space, Row, Col, DatePicker, Select, Input, Pagination } from 'antd';
import { FileTextOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PurchaseOrderStatus from './component/status/PurchaseOrderStatus';
import './OrderFullfillList.less';
import purchaseOrderApi from '../servapi/purchase_order';
import saleOrderApi from '../servapi/sale_order';
import SaleOrderStatus from './component/status/SaleOrderStatus';
import SupplierOrderStatus from './component/status/SupplierOrderStatus';
import OrderPayStatus from './component/status/OrderPayStatus';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dataSource = [
  // 示例数据
  {
    key: '1',
    soOrder: 'S3048557127001',
    orderSource: 'M172105936196300001',
    createdTime: '2024/07/16 00:05:27',
    customer: 'Nicolas, Gotland, 杭州市滨江区, Sweden, 浙新小区',
  },
  // 添加更多数据
];

const columns = [
  {
    title: 'SO订单',
    dataIndex: 'soOrder',
    key: 'soOrder',
    render: (text) => (
      <Descriptions>
        <Descriptions.Item>
          <FileTextOutlined /> <span className="ml-8">SO订单</span>
          <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{text}</a>
        </Descriptions.Item>
      </Descriptions>
    ),
  },
  {
    title: '订单源',
    dataIndex: 'orderSource',
    key: 'orderSource',
    render: (text) => (
      <Descriptions>
        <Descriptions.Item>
          <img alt="Order Source" width="14" height="14" src="" style={{ marginRight: 4, marginBottom: 2 }} />
          <span style={{ marginLeft: 4 }}>订单</span>
          <span>{text}</span>
        </Descriptions.Item>
      </Descriptions>
    ),
  },
  {
    title: '创建时间 (UTC+8)',
    dataIndex: 'createdTime',
    key: 'createdTime',
    render: (text) => (
      <Descriptions>
        <Descriptions.Item>
          <ClockCircleOutlined /> <span className="ml-8">创建时间 (UTC+8)</span>
          <span>{text}</span>
        </Descriptions.Item>
      </Descriptions>
    ),
  },
  {
    title: '客户',
    dataIndex: 'customer',
    key: 'customer',
    render: (text) => (
      <Descriptions>
        <Descriptions.Item>
          <UserOutlined /> <span className="ml-8">客户</span>
          <span>{text}</span>
          <a className="ml-10">
            <FormOutlined />
          </a>
        </Descriptions.Item>
      </Descriptions>
    ),
  },
];

const SupplierOrderCard = ({ supplierOrder }) => {
  const { t } = useTranslation();
  const products = [
    {
      key: '1',
      name: '德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力',
      variant: '全榛子白巧克力100g',
      price: 'CN ¥10.5',
      quantity: 1,
      submittedPackage: 0,
      returnedQuantity: 0,
      imageUrl: 'https://cbu01.alicdn.com/img/ibank/O1CN01fYaxNN1S2bf263TKC_!!2210898012189-0-cib.jpg',
    },
    {
      key: '2',
      name: '德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力',
      variant: '全榛子黑巧克力100g',
      price: 'CN ¥10.5',
      quantity: 1,
      submittedPackage: 0,
      returnedQuantity: 0,
      imageUrl: 'https://cbu01.alicdn.com/img/ibank/O1CN01fYaxNN1S2bf263TKC_!!2210898012189-0-cib.jpg',
    },
    {
      key: '3',
      name: '德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力',
      variant: '扁桃仁牛奶巧克力100g',
      price: 'CN ¥10.5',
      quantity: 1,
      submittedPackage: 0,
      returnedQuantity: 0,
      imageUrl: 'https://cbu01.alicdn.com/img/ibank/O1CN01fYaxNN1S2bf263TKC_!!2210898012189-0-cib.jpg',
    },
  ];

  const columns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span>{record.productName}</span>
        </div>
      ),
      width: '35%'
    },
    {
      title: 'Sku',
      dataIndex: 'skuInfo',
      key: 'skuInfo',
      width: '15%'
    },
    {
      title: 'Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: '15%',
      render: (_, record) => (<span>{t("common.currency.symbol")}{record.unitPrice}</span>)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (text, record) => (
        <>
          <div><strong>{record.quantity}</strong></div>
        </>
      ),
      width: '15%'
    },
    {
      title: 'Detail',
      key: 'detail',
      render: (text, record) => (
        <>
          <div style={{ color: '#999', cursor: 'pointer' }}>提交包裹：{record.submittedPackage}</div>
          <div style={{ color: '#999', cursor: 'pointer' }}>退货数量：{record.returnedQuantity}</div>
        </>
      ),
      width: '20%'
    },
  ];

  return (
    <Card bodyStyle={{ padding: 0, }} style={{ borderRadius: 0 }} className='OVWKvl3A'>
      <Card bodyStyle={{ paddingTop: 8, paddingBottom: 8 }} style={{ border: 0 }}>
        <Card.Meta
          title={
            <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.supplierName ? supplierOrder.supplierName : supplierOrder.supplierSellerNo}</span>
              </div>
            </div>
          }
          description={
            <Descriptions size="small" column={3} style={{ paddingLeft: 8 }}>
              {/* <Descriptions.Item>1688订单：<a href="/cn/admin/orders/po/P3048557127001">P3048557127001</a></Descriptions.Item> */}
              <Descriptions.Item>订单状态：<Tag><SupplierOrderStatus status={supplierOrder.status} /></Tag></Descriptions.Item>
              <Descriptions.Item>支付状态：<Tag><OrderPayStatus status={supplierOrder.payStatus} /></Tag></Descriptions.Item>
              <Descriptions.Item>物流状态：<Tag>查询物流</Tag></Descriptions.Item>
            </Descriptions>
          }
        />
      </Card>
      <div>
        <Table
          columns={columns}
          dataSource={supplierOrder.orderProducts || []}
          pagination={false}
          size="small"
          style={{ marginBottom: 16, borderRadius: 0 }}
        />
      </div>
    </Card>
  );
};

const PurchaseOrderCard = ({ purchaseOrder = {}, onConfirm }) => {

  const text = '11';
  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent', }} style={{ marginBottom: 16, borderRadius: 0, border: 0 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8, background: '#eff8ff' }} >
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购订单：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{purchaseOrder.id}</a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购状态：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}><PurchaseOrderStatus status={purchaseOrder.status}/></a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                  <span>2024/07/16 00:05:27</span>
                </Descriptions.Item>
              </Descriptions></td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">物流状态：</span>
                  <span>物流查询</span>
                </Descriptions.Item>
              </Descriptions></td>

            <td>
              <div>
                {
                  purchaseOrder.status !== 'NEW' && (
                    <Descriptions style={{ paddingTop: 8 }}>
                      <Descriptions.Item>
                        <UserOutlined /> <span className="ml-8">已支付订单数：</span>
                        <span>{purchaseOrder.purchaseSupplierOrders.filter(purchaseSupplierOrder => purchaseSupplierOrder.payStatus === 'PAID').length}/{purchaseOrder.purchaseSupplierOrders.length}</span>
                      </Descriptions.Item>
                    </Descriptions>
                  )
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {
        (purchaseOrder.purchaseSupplierOrders || []).map(supplierOrder => <SupplierOrderCard supplierOrder={supplierOrder} />)
      }

    </Card>
  )
}

const SearchOrders = ({ onSync }) => {
  return (
    <Card title="搜索订单" style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>店铺</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="shop1">店铺1</Option>
            <Option value="shop2">店铺2</Option>
          </Select>
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单号或商品名称</div>
          <Input placeholder="订单号或商品名称" style={{ width: '260px' }} />
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单来源</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="source1">来源1</Option>
            <Option value="source2">来源2</Option>
          </Select>
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>物流状态</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="source1">来源1</Option>
            <Option value="source2">来源2</Option>
          </Select>
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>收件人姓名</div>
          <Input placeholder="输入收件人姓名" style={{ width: '260px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单状态</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="status1">状态1</Option>
            <Option value="status2">状态2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>国家/地区</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="country1">国家1</Option>
            <Option value="country2">国家2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>时间区间</div>
          <RangePicker style={{ width: '256px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', display: 'flex', alignItems: 'flex-end' }}>
          <Button type="primary" style={{ marginRight: 8 }}>搜索</Button>
          <Button>清除</Button>
        </div>
      </div>
    </Card>
  );
};

const OrderFullfillCard = ({ saleOrderFulfillment }) => {
  const [purchaseOrder, setPurchaseOrder] = useState({});

  useEffect(() => {
    purchaseOrderApi.getPurchaseOrder(20)
      .then(setPurchaseOrder)
  }, [])

  const text = '111';
  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent' }} style={{ marginBottom: 16 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8 }} >
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">销售订单：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{saleOrderFulfillment.id}</a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">订单状态：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{<SaleOrderStatus status={saleOrderFulfillment.orderStatus} />}</a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                  <span>2024/07/16 00:05:27</span>
                </Descriptions.Item>
              </Descriptions></td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">客户信息：</span>
                  <span>Nicolas, Gotland, 杭州市滨江区, Sweden, 浙新小区</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">已采商品数：</span>
                  <span>{saleOrderFulfillment.orderProducts.filter(orderProduct => orderProduct.psoOrderId > 0).length}/{saleOrderFulfillment.orderProducts.length}</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">店铺：</span>
                  <span>{saleOrderFulfillment.shop.shopName}</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
          </tr>
        </tbody>
      </table>
      {
        saleOrderFulfillment.purchaseOrders.map(purchaseOrder => <PurchaseOrderCard purchaseOrder={purchaseOrder} />)
      }
    </Card>
  )
}


const OrderFullfilList = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchSaleOrderFulfillments({ pagination })
  }, [pagination.page, pagination.size])

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };

  const fetchSaleOrderFulfillments = async ({ pagination }) => {
    const pageData = await saleOrderApi.getSaleOrderFulfillments({
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.saleOrderFulfillments || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  订单履约
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <SearchOrders />
        <div className="order-detail-list" style={{ margin: 20 }}>
          {
            data.map(saleOrderFulfillment => <OrderFullfillCard saleOrderFulfillment={saleOrderFulfillment} />)
          }

          {/* <OrderFullfillCard /> */}
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }} >
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
      </div>
    </>

  );
};

export default OrderFullfilList;
