import React from 'react';
import productApi from '../../servapi/product';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trans } from '../../util/transobj';

export default props => {
  const { t, i18n } = useTranslation();
  const [pageData, setPageData] = React.useState({});

  React.useEffect(() => {
    productApi.search({
      "beginPage": 0,
      "country": i18n.language,
      "categoryId": 1031861,
      "pageSize": 10,
    })
      .then(setPageData)

    // console.log(result)
    // setPageData(result);


  }, [i18n.language]);

  return (
    <section className="products-main-box products-main-box-logistics">
      <header className="products-main-box-header">
        <h5 className="products-main-box-title">{t("productIndex.bestSellers.title")}</h5>
      </header>
      <div className="products-main-box-body">
        <ul className="product-list">
          {
            (pageData.data || []).map(product => (
              <Link to={`/product/detail/${product.offerId}`}>
                <li className="product-item" alt={trans(product, 'subjectTrans', 'subject')}>
                  <img src={product.imageUrl} alt={trans(product, 'subjectTrans', 'subject')} className="product-thumbnail" />
                  <p className="product-name truncate">{trans(product, 'subjectTrans', 'subject')}</p>
                </li>
              </Link>
            ))
          }

          {/* <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li>
          <li className="product-item">
            <img src="https://cbu01.alicdn.com/img/ibank/O1CN01N8AYwg1xeOUcRJLnB_!!2425976468-0-cib.jpg" alt="Product 1" className="product-thumbnail" />
            <p className="product-name">Product 1</p>
          </li> */}
        </ul>
      </div>
    </section>
  )
}