import i18n from 'i18next';

export function trans(obj, transProp, prop) {
  if (!obj) {
    return;
  }

  if (i18n.language === 'zh-CN') {
    return obj[prop] || obj[transProp]
  }

  return obj[transProp];
}