import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import LanguageSwitcher from '../component/LanguageSwitcher';
import './Head.less';



const langOptions = {
  'en-US': { currency: 'USD', language: 'English'},
  'es-ES': { currency: 'EUR', language: 'Lengua española'},
  'ru-RU': { currency: 'RU', language: 'Русский'},
  'zh-CN': { currency: 'CNY', language: '简体中文'},
}

export default inject('userStore')(observer(({ userStore }) => {
  const { t, i18n } = useTranslation();
  const langOption = langOptions[i18n.language || 'en-US'];

  console.log(i18n.language, 'language....')

  console.log(userStore.userInfo.username, 'userInfo......')
  return (
    <div className="h-[72px] bg-no-repeat bg-cover flex-center flex-row relative" style={{ background: '#0055CC', zIndex: 99 }}>
      <div className="container flex-row justify-between">
        <div className="h-[44px] my-[14px] flex-row pl-[9px] pr-[12px]">
          <img src="/images/logo.png"/>
        </div>
        <div className="flex">
          <div className="language-action  h-[72px] cursor-pointer flex-col justify-center relative group">
            <div className="h-[25px] mx-[8px] flex-row">
              <img
                className="w-[22px] h-[22px] mt-[1px]"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngea3b5f7daec7b46949a119dfd9f5af8d31d5fbbc8b0afc72f959687405087d87"
              />
              <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-center whitespace-nowrap leading-[25px] ml-[8px]">{langOption.language}</span>
              <span className="w-[3px] h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Light font-300 text-center whitespace-nowrap leading-[25px] ml-[8px]">|</span>
              <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-center whitespace-nowrap leading-[25px] ml-[8px]">{langOption.currency}</span>
              <img
                className="w-[12px] h-[6px] my-[9px] mx-[6px]"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng86886defa61a1e7a7d02e875dca0ff0f116935ee83420e3301bdf8692166c5e9"
              />
            <LanguageSwitcher />
            </div>
            <div className="h-[4px] w-full mt-[19px] absolute bottom-0 transition-opacity opacity-0 group-hover:opacity-100 " style={{ background: 'rgba(6, 215, 162, 1)' }}></div>
       
          </div>
          {
            userStore?.userInfo?.username && (
              <Link to="/dashboard">
                <div className="bg-rgba(216, 216, 216, 0) h-[72px] mx-[24px] cursor-pointer flex-row justify-between relative group">
                  <img
                    className="w-[22px] h-[22px] mt-[25px] ml-[8px]"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb8a01698d213eae7e7169433f7b467e494eb9b2154f38b81a537fa5622842b47"
                  />
                  {/* <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-left whitespace-nowrap leading-[25px] my-[24px] mx-[8px]">{t("index.header.login")}</span> */}
                  <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-left whitespace-nowrap leading-[25px] my-[24px] mx-[8px]">{userStore.userInfo.username}</span>
                  <div className="h-[4px] w-full mt-[19px] absolute bottom-0 transition-opacity opacity-0 group-hover:opacity-100 display-block" style={{ background: 'rgba(6, 215, 162, 1)' }}></div>
                </div>
              </Link>
            )
          }
          {
              !userStore?.userInfo?.username && (
                <Link to="/login">
                  <div className="bg-rgba(216, 216, 216, 0) h-[72px] mx-[24px] cursor-pointer flex-row justify-between relative group">
                    <img
                      className="w-[22px] h-[22px] mt-[25px] ml-[8px]"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb8a01698d213eae7e7169433f7b467e494eb9b2154f38b81a537fa5622842b47"
                    />
                    <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-left whitespace-nowrap leading-[25px] my-[24px] mx-[8px]">{t("index.header.login")}</span>
                    {/* <span className="h-[25px] overflow-wrap-break-word text-white text-[16px] font-AlibabaSans-Medium font-500 text-left whitespace-nowrap leading-[25px] my-[24px] mx-[8px]">admin@gmail.com</span> */}
                    <div className="h-[4px] w-full mt-[19px] absolute bottom-0 transition-opacity opacity-0 group-hover:opacity-100 display-block" style={{ background: 'rgba(6, 215, 162, 1)' }}></div>
                  </div>
                </Link>
              )
          }
        </div>
      </div>
    </div>
  )
}))