import React from 'react';

import './ProductDetailCard.less';
import { Card, Row, Col, Typography, Tag, Button, Space, Divider, Image, message } from 'antd';
import SkuSelector from './SkuSelector';
import { useTranslation } from 'react-i18next';
import listingProductApi from '../../servapi/listing_product';
import productApi from '../../servapi/product';
import { trans } from '../../util/transobj';

const { Text } = Typography;

const goodsTags = [
  '大R字母', '长耳兔', 'FUTURA', '仙人掌', 'VGNU', '大力水手', '不二家', '爱心米奇', '大红心', '粉色大脸猫', 
  '帆船', '白T--纯色不带图'
];

const goodsSizes = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];

const ProductImageGallery = ({ thumbnailImages, skuInfo }) => {
  const [currentImage, setCurrentImage] = React.useState(thumbnailImages[0]);

  const handleThumbnailHover = (imageSrc) => {
    setCurrentImage(imageSrc);
  };

  React.useEffect(() => {
    if (!skuInfo) {
      return;
    }

    const skuImages = skuInfo.skuAttributes.map(skuAttribute => skuAttribute.skuImageUrl).filter(image => image != null);

    if (skuImages.length > 0) {
      setCurrentImage(skuImages[0]);
    }

  }, [skuInfo])

  React.useEffect(() => {
    setCurrentImage(thumbnailImages[0])
  }, [thumbnailImages])

  return (
    <div className="goods-img-container">
      <div className="goods-img-detail">
        <Image src={currentImage} />
      </div>
      <div className="goods-img-list">
        <ul className="justify-between">
          {thumbnailImages.map((image, index) => (
            <li key={index}>
              <div className={`li-item-img ${currentImage === image ? 'li-item-select':'' }`} onMouseEnter={() => handleThumbnailHover(image)}>
                <Image src={image} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

function processSkuData(productSkuInfos) {
  let skuAttrs = [];
  let skuIdsMap = {};

  productSkuInfos.forEach(skuInfo => {
      let skuAttributes = skuInfo.skuAttributes;

      skuAttributes.forEach(attribute => {
          let attributeName = attribute.attributeName;
          let attributeNameTrans = attribute.attributeNameTrans;
          let attributeValue = attribute.value;
          let attributeValueTrans = attribute.valueTrans;

          // Check if attributeName already exists in skuAttrs
          let existingAttr = skuAttrs.find(attr => attr.attributeName === attributeName);

          if (!existingAttr) {
              // If attributeName does not exist, create a new attribute object
              skuAttrs.push({
                  attributeName,
                  attributeNameTrans,
                  attributeValues: [{
                      value: attributeValue,
                      valueTrans: attributeValueTrans
                  }]
              });
          } else {
              // If attributeName exists, add attributeValue to attributeValues
              const values = existingAttr.attributeValues.map(av => av.value);
              if (values.indexOf(attributeValue) < 0) {
                existingAttr.attributeValues.push({
                    value: attributeValue,
                    valueTrans: attributeValueTrans
                });
              }
          }
      });

      // Create skuId mapping for combination of attributes
      let attributeValues = skuAttributes.map(attr => attr.value).join('__');
      skuIdsMap[attributeValues] = skuInfo.skuId.toString();
  });

  return { skuAttrs, skuIdsMap };
}

export default ({ productDetail }) => {
  const { t } = useTranslation();
  const [skuInfo, setSkuInfo] = React.useState()

  // const thumbnailImages = [
  //   'https://cbu01.alicdn.com/img/ibank/O1CN01MDYyOh2EQW9CKrSoi_!!2214378058739-0-cib.jpg',
  //   'https://cbu01.alicdn.com/img/ibank/O1CN01R3e1GC2EQW97BtFbk_!!2214378058739-0-cib.jpg',
  //   'https://cbu01.alicdn.com/img/ibank/O1CN01vFro1e2EQW97BtvF7_!!2214378058739-0-cib.jpg',
  //   'https://cbu01.alicdn.com/img/ibank/O1CN01MHWC4l2EQW97BwfiO_!!2214378058739-0-cib.jpg',
  //   'https://cbu01.alicdn.com/img/ibank/O1CN01Bdlj021xeOhIyYIgo_!!2207727426468-0-cib.jpg'
  // ]

  const thumbnailImages = productDetail?.productImage?.images??[];

  let skuData;
  if (productDetail?.productSkuInfos) {
    skuData = processSkuData(productDetail.productSkuInfos);
  }


  const handleSkuSelected = skuId => {
    const skuInfos = productDetail?.productSkuInfos?.filter(skuInfo => `${skuInfo.skuId}` === skuId);

    if (skuInfos.length > 0) {
      setSkuInfo(skuInfos[0])
    }
    console.log(skuId, 'ssss.')
  }

  const getPriceRange = productSaleInfo => {
    if (!productSaleInfo.priceRangeList || productSaleInfo.priceRangeList.length <= 0) {
      return '';
    }

    if (productSaleInfo.priceRangeList.length == 1) {
      return productSaleInfo.priceRangeList[0].price
    }

    return `${productSaleInfo.priceRangeList[0].price} ~ ${productSaleInfo.priceRangeList[productSaleInfo.priceRangeList.length - 1].price}`
  }

  const handleAddProduct = async () => {

    const hide = message.loading('正在添加到商品列表', 0)
    const product = await listingProductApi.addListingProductsByOfferId({
      "offerId": parseInt(productDetail.offerId),
      "country": "en"
    })

    hide();
    message.success('商品已添加到商品列表[' + product.id + ']');
    
    // productApi.createSpuProduct({
    //   "offerId": parseInt(productDetail.offerId),
    //   "country": "en"
    // })
    // .then(() => {
    //   message.success(t('productDetail.message.itemAdd'))
    // })
    // .catch(console.log);
  }

  const getBrand = () => {
    const filtered = (productDetail?.productAttribute || []).filter(attr => attr.attributeName === '品牌');

    if (filtered.length > 0) {
      return filtered[0].valueTrans;
    }
  }

  
  return (
    <Card className="ant-card-bordered mb-[16px]">
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={8}>
          <ProductImageGallery thumbnailImages={thumbnailImages} skuInfo={skuInfo}/>
          {/* <div className="goods-img-container">
            <div className="goods-img-detail">
              <Image src="https://cbu01.alicdn.com/img/ibank/O1CN01MHWC4l2EQW97BwfiO_!!2214378058739-0-cib.jpg" />
            </div>
            <div className="goods-img-list">
              <ul className="justify-between">
                <li><div className="li-item-img"><Image src="https://cbu01.alicdn.com/img/ibank/O1CN01MDYyOh2EQW9CKrSoi_!!2214378058739-0-cib.jpg" /></div></li>
                <li><div className="li-item-img"><Image src="https://cbu01.alicdn.com/img/ibank/O1CN01R3e1GC2EQW97BtFbk_!!2214378058739-0-cib.jpg" /></div></li>
                <li><div className="li-item-img"><Image src="https://cbu01.alicdn.com/img/ibank/O1CN01vFro1e2EQW97BtvF7_!!2214378058739-0-cib.jpg" /></div></li>
                <li><div className="li-item-img li-item-select"><Image src="https://cbu01.alicdn.com/img/ibank/O1CN01MHWC4l2EQW97BwfiO_!!2214378058739-0-cib.jpg" /></div></li>
                <li><div className="li-item-img"><Image src="https://cbu01.alicdn.com/img/ibank/O1CN01Bdlj021xeOhIyYIgo_!!2207727426468-0-cib.jpg" /></div></li>
              </ul>
            </div>
          </div> */}
        </Col>
        <Col xs={24} xl={16}>
          <div className="right-part-container">
            <div className="goods-name">
              {/* <Text strong className="text-16 text-black mb-10">2024新款正肩重磅纯棉短袖T恤女时尚百搭显瘦白色上衣打底衫男夏</Text> */}
              <Text strong className="text-16 text-black mb-10">{trans(productDetail, 'subjectTrans', 'subject')}</Text>
            </div>
            <div className="shop-msg flex-center-vertical">
              <a className="shop-name-container cursor-default">
                <i className="platform-icon" style={{ backgroundImage: 'url(...)', backgroundSize: '16px', height: '16px', width: '16px' }} />
                <span className="shop-name truncate">{getBrand()}</span>
              </a>
              <div className="ml-auto">
                {/* <a href="https://detail.1688.com/offer/774191813240.html" target="_blank" rel="noopener noreferrer" className="ant-btn ant-btn-primary ant-btn-sm ant-btn-background-ghost">
                  <span className="anticon anticon-link" />
                  <span>原始链接</span>
                </a> */}
                {/* <Button type="primary" size="small" className="ml-8">
                  <span className="anticon anticon-search" />
                  <span>查找同款1688货源</span>
                </Button> */}
              </div>
            </div>
            <Divider className="ant-divider-horizontal" />
            <div className="d-flex items-center">
              {
                productDetail?.productSaleInfo && (
                  <>
                    <Text className="goods-price text-red">{t("common.currency.symbol")}{getPriceRange(productDetail?.productSaleInfo)}</Text>
                  </>
                )
              }
              <Text className="goods-min-qty ml-[8px]">{t("productDetail.productCard.minimumNum", { number: productDetail?.minOrderQuantity})}</Text>
            </div>
            {/* <div className="mb-20">
              <Text className="text-gray">预估国内运费:</Text>
              <Text className="text-black ml-5">CN ¥ 6</Text>
            </div> */}
            <div className="sku-list-cont-default">
              {/* {
                skuData?.skuAttrs?.map(skuAttr => (
                  <div className="sku-list">
                  <div className="text-black mb-8">{skuAttr.attributeNameTrans}:</div>
                  <div className="mb-16">
                    {skuAttr.attributeValues.map(skuAttrValue => (
                      <Tag key={skuAttrValue.valueTrans} style={{ marginBottom: 8 }}>
                        <span className="tag-content">{skuAttrValue.valueTrans}</span>
                      </Tag>
                    ))}
                  </div>
                </div>
                ))
              } */}
              <SkuSelector skuData={skuData} onSkuSelected={handleSkuSelected}/>
            </div>
            <div className="goods-sub-btn-box-border flex-center-vertical">
              <Space>
                <Button type="primary" className="btn-save-product add-to-import-list" onClick={handleAddProduct}>
                  <span className="anticon anticon-plus" />
                  <span>{t('productDetail.productCard.btnText')}</span>
                </Button>
              </Space>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}