import React, { useState } from 'react';
import { Card, Button, Descriptions, Divider, DatePicker, Table, Tag, Typography, Row, Col, Select, Input, message, Pagination } from 'antd';
import Dashboard from './Dashboard';
import './SaleOrderList.less';
import saleOrderApi from '../servapi/sale_order';
import shopApi from '../servapi/shop';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const orderData = {
  orderTime: '2024/07/16 00:02:41',
  orderNumber: 'M172105936196300001',
  store: 'wpmi',
  orderNotes: 'N/A',
  deliveryAddress: 'Nicolas, Kuo, softlight0629@gmail.com, Sweden, 哥特兰岛, 杭州市滨江区, 325000, 浙新小区',
  products: [
    {
      key: '4892938257804',
      image: 'https://cbu01.alicdn.com/img/ibank/O1CN01bMVvI01S2bezQDpM0_!!2210898012189-0-cib.jpg',
      link: 'https://detail.1688.com/offer/642154137407.html',
      description: '德国进口RitterSport瑞特斯波德巧克力运动排块牛奶夹心黑巧克力',
      details: '全榛子白巧克力100g',
      price: '10.50',
      quantity: 1
    },
    // Add more product objects as needed
  ]
};
function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function constructAddress(json) {
  const {
    deliveryCity,
    deliveryCompany,
    deliveryFirstName,
    deliveryLastName,
    deliveryPostCode,
    deliveryProvince,
    deliveryState,
    deliveryStreetAddress,
    deliveryTelephone
  } = json;

  // const email = "softlight0629@gmail.com";
  // const country = "Sweden";
  // const state = "哥特兰岛";

  let addressComponents = [];

  if (deliveryFirstName) addressComponents.push(deliveryFirstName);
  if (deliveryLastName) addressComponents.push(deliveryLastName);
  // addressComponents.push(email);
  // addressComponents.push(country);
  // addressComponents.push(state);
  if (deliveryState) addressComponents.push(deliveryState);
  if (deliveryProvince) addressComponents.push(deliveryProvince);
  if (deliveryCity) addressComponents.push(deliveryCity);
  if (deliveryPostCode) addressComponents.push(deliveryPostCode);
  if (deliveryStreetAddress) addressComponents.push(deliveryStreetAddress);
  if (deliveryCompany) addressComponents.push(deliveryCompany);
  if (deliveryTelephone) addressComponents.push(deliveryTelephone);

  return addressComponents.join(", ");
}

const SaleOrderStatusText = ({ status }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return '已创建';
      case 'PENDING_PAY':
        return '待支付';
      case 'PAYED':
        return '已支付';
      case 'CONFIRMED':
        return '已确认';
      case 'SALE_POOL_NEW':
        return '销售池待处理';
      case 'SALE_POOL_PROCESSING':
        return '销售池处理中';
      case 'PURCHASE_POOL_PROCESSING':
        return '等待采购';
      case 'PARTIAL_PURCHASING':
        return '部分已采购';
      case 'PURCHASING':
        return '采购中';
      case 'COMPLETE':
        return '已完成';
      case 'CANCELLED':
        return '已取消';
      case 'HOLDING':
        return '暂缓';
      case 'SHIPPED':
        return '已发货';
      case 'DELIVERED':
        return '已送达';
      case 'REFUNDED':
        return '已退款';
      case 'RETURNED':
        return '已退货';
      case 'FAILED':
        return '已失败';
      default:
        return '未知状态';
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};

const OrderDetails = ({ orderDetail, onConfirm }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Product',
      dataIndex: 'description',
      key: 'description',
      width: '50%',
      render: (text, record) => (
        <div className="d-flex items-start" style={{ display: 'flex', alignItems: 'center'}}>
          <div style={{ float: 'left', width: '60px', height: '60px', marginRight: '16px' }}>
            <img src={record.productImage} alt="" style={{ width: '100%', height: '100%', borderRadius: '0px', border: '1px solid #eee' }} />
          </div>
          <div style={{ maxWidth: 520 }}>
            <Text ellipsis={{ rows: 2, expandable: false, width: 320 }}>
              <a href={record.productLink} target="_blank" rel="noopener noreferrer">{record.productName}</a>
            </Text>
          </div>
        </div>
      )
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '15%',
      render: (text, record) => (
        <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{t("common.currency.symbol")}{record.unitPrice}</span>
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '15%',
      render: (text, record) => (
        <span>{record.productQuantity}</span>
      )
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: '20%',
      render: text => (
        <span>
          <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><Tag>已发货</Tag></Text>
          <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><Tag>物流查询</Tag></Text>
        </span>
      
      )
    },
  ];

  // Nicolas, Kuo, softlight0629@gmail.com, Sweden, 哥特兰岛, 杭州市滨江区, 325000, 浙新小区
  const deliveryAddress = ``;

  return (
    <Card style={{ marginTop: 16, minHeight: 200 }} bodyStyle={{ padding: 0 }}>
      <Card bodyStyle={{ padding: 0 }}>
        <div className="lkVn3iOw">
          <Card bordered style={{ border: 0 }}
          >
            <Card.Meta
              style={{ display: 'flex' }}
              title={
                <div className="aHPEc5kL" style={{ display: 'flex' }}>
                  <div style={{ marginRight: 8 }}>
                    <strong>{formatDate(orderDetail.createdTime)}</strong>
                  </div>
                  <div style={{ marginRight: 8 }}>订单：&nbsp;{orderDetail.id}</div>
                  {/* <div><Tag color="blue">打开</Tag></div> */}
                </div>
              }
              description={
                <div>
                  <Button style={{ width: 100 }}>编 辑</Button>
                  {
                    orderDetail.orderStatus !== 'NEW' && (
                      <Button style={{ margin: '0 8px' }}>取 消</Button>
                    )
                  }
                  {
                    orderDetail.orderStatus === 'NEW' && (
                      <>
                        <Button style={{ margin: '0 8px' }}>删 除</Button>
                        <Button type="primary" onClick={() => onConfirm(orderDetail)}>确 认</Button>
                      </>

                    )
                  }

                </div>
              }
            />
          </Card>
          <div style={{ padding: '16px 16px 0', background: '#FAFAFA', borderTop: '1px solid #E8E8E8' }}>
            <Descriptions column={2}>
              <Descriptions.Item label="店铺" span={1}>{orderDetail.shop.shopName}</Descriptions.Item>
              <Descriptions.Item label="订单备注" span={3}>{orderData.orderNotes}</Descriptions.Item>
            </Descriptions>
            <Descriptions column={10}>
              <Descriptions.Item label="送货地址" span={5}>{constructAddress(orderDetail)}</Descriptions.Item>
              <Descriptions.Item label="订单状态" span={5}>
                <SaleOrderStatusText status={orderDetail.orderStatus} />
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Divider style={{ margin: 0 }} />
          <Table
            columns={columns}
            dataSource={orderDetail.orderProducts}
            pagination={false}
            rowKey="key"
          />
        </div>
      </Card>
    </Card>
  );
};

const SearchOrders = ({ onSync }) => {
  return (
    <Card title="搜索订单" style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>店铺</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="shop1">店铺1</Option>
            <Option value="shop2">店铺2</Option>
          </Select>
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单号或商品名称</div>
          <Input placeholder="订单号或商品名称" style={{ width: '260px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单来源</div>
          <Select placeholder="全部" style={{ width: '300px' }} allowClear>
            <Option value="source1">来源1</Option>
            <Option value="source2">来源2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>收件人姓名</div>
          <Input placeholder="输入收件人姓名" style={{ width: '260px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>状态</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="status1">状态1</Option>
            <Option value="status2">状态2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>国家/地区</div>
          <Select placeholder="全部" style={{ width: '140px' }} allowClear>
            <Option value="country1">国家1</Option>
            <Option value="country2">国家2</Option>
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>时间区间</div>
          <RangePicker style={{ width: '256px' }} />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', display: 'flex', alignItems: 'flex-end' }}>
          <Button type="primary" style={{ marginRight: 8 }}>搜索</Button>
          <Button style={{ marginRight: 8 }} >清除</Button>
          <Button type="primary" style={{ marginRight: 8 }} onClick={onSync}>同步订单</Button>
        </div>
      </div>
    </Card>
  );
};


export default props => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  React.useEffect(() => {
    fetchSaleOrders({ pagination })
  }, [pagination.current, pagination.pageSize])

  const fetchSaleOrders = async ({ pagination }) => {
    const pageData = await saleOrderApi.getSaleOrders({
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.saleOrders || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const handleConfirmSaleOrder = async orderDetail => {
    const hide = message.loading("正在确认订单", 0);
    const salePoolOrder = await shopApi.confirmSaleOrder(orderDetail.shop.id, orderDetail.id);
    hide();
    message.success("订单已确认，等待处理, 进入订单池[" + salePoolOrder.id + "]");

    fetchSaleOrders({ pagination });
  }

  const handleSyncSaleOrders = async () => {
    const hide = message.loading("正在同步订单", 0);
    const resp = await saleOrderApi.syncSaleOrders(1);
    hide();
    message.success('订单同步完毕');

    fetchSaleOrders({ pagination });
  }

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  店铺订单
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <SearchOrders onSync={handleSyncSaleOrders} />
        <div className='order-details-list' style={{ margin: 20 }}>
          {
            data.map(orderDetail => <OrderDetails orderDetail={orderDetail} onConfirm={handleConfirmSaleOrder} />)
          }
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
      </div>
    </>
  )
}