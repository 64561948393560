import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = props => {
  const { t, i18n } = useTranslation();

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
  }

  const language = i18n.language;

  return (
    <div className="language-switcher absolute top-[72px] transition-opacity" style={{ zIndex: 999, left: '-100px'}}>
      <div className="w-[526px] bg-no-repeat bg-cover flex-center my-[8px] flex-col" style={{ background: '#fff' }}>
        <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[18px] font-AlibabaSans-Medium font-bold text-center whitespace-nowrap leading-[28px] my-[16px] mx-[97px]"
        >{t('index.lang.title')}</span>
        <div className="w-[502px] h-[92px] my-[16px] ml-[12px] flex-row mb-[16px]">
          <div className="w-[103px] h-[92px] flex-col justify-between">
            <div className="bg-rgba(216, 216, 216, 0) h-[46px] w-[103px] flex-col">
              <span className="w-[32px] h-[22px] overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[12px] mx-[20px]" style={language === 'zh-CN' ? { color: '#0055CC'} : {}} onClick={() => changeLanguage('zh-CN')}>简体</span>
            </div>
            <div className="bg-rgba(216, 216, 216, 0) h-[46px] w-[103px] flex-col">
              <span className="w-[63px] h-[25px] overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-left whitespace-nowrap leading-[25px] my-[11px] mx-[20px]" style={language === 'ru-RU' ? { color: '#0055CC'} : {}} onClick={() => changeLanguage('ru-RU')}>Русский</span>
            </div>
          </div>
          <div className="w-[173px] h-[81px] mt-[11px] flex-col justify-between">
            <span className="w-[57px] h-[25px] overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Medium font-500 text-justify whitespace-nowrap leading-[25px] ml-[20px]" style={language === 'en-US' ? { color: '#0055CC'} : {}}onClick={() => changeLanguage('en-US')}>English</span>
            <div className="h-[46px] bg-no-repeat bg-cover mt-[10px] w-[173px] flex-col">
              <span className="w-[133px] h-[25px] overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-justify whitespace-nowrap leading-[25px] my-[10px] mx-[20px]" style={language === 'es-ES' ? { color: '#0055CC'} : {}} onClick={() => changeLanguage('es-ES')}>Lengua española</span>
            </div>
          </div>
          <img
            className="w-[1px] h-[92px] ml-[12px]"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3cd217b94170a40928c9dcb851dba9fe58a243a5efbfa53f96d45c57c2d73899"
          />
          <div className="w-[92px] h-[92px] ml-[11px] flex-col justify-between">
            <div className="bg-rgba(216, 216, 216, 0) h-[46px] w-[110px] flex-col items-center justify-center" style={language === 'zh-CN' ? { background: '#f6f6f6'} : {}}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-PingFangSC-Regular font-normal text-left whitespace-nowrap leading-[22px]" style={language === 'zh-CN' ? { color: '#0055CC'} : {}}>CNY</span>
            </div>
            <div className="bg-rgba(216, 216, 216, 0) h-[46px] w-[110px] flex-col items-center justify-center" style={language === 'ru-RU' ? { background: '#f6f6f6'} : {}}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-PingFangSC-Regular font-normal text-left whitespace-nowrap leading-[22px]" style={language === 'ru-RU' ? { color: '#0055CC'} : {}}>RU</span>
            </div>
          </div>
          <div className="w-[92px] h-[92px] flex-col justify-between">
            <div className="h-[46px] bg-no-repeat bg-cover w-[110px] flex-col items-center justify-center" style={language === 'en-US' ? { background: '#f6f6f6'} : {}}>
              <span className=" overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Medium font-500 text-justify whitespace-nowrap leading-[25px]" style={language === 'en-US' ? { color: '#0055CC'} : {}}>USD</span>
            </div>
            <div className="h-[46px] bg-no-repeat bg-cover w-[110px] flex-col items-center justify-center" style={language === 'es-ES' ? { background: '#f6f6f6'} : {}}>
              <span className="overflow-wrap-break-word text-rgba(0, 0, 0, 1) text-[16px] font-AlibabaSans-Regular font-normal text-justify whitespace-nowrap leading-[25px]" style={language === 'es-ES' ? { color: '#0055CC'} : {}}>EUR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageSwitcher;