import './App.less';
import React, { Suspense } from 'react';
import Index from './pages/Index';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { inject } from 'mobx-react';
import './react-i18next-config';
import { Spin } from 'antd';
import ProductsIndex from './pages/ProductsIndex';
import ProductDetail from './pages/ProductDetail';
import ProductsList from './pages/ProductsList';
import ProductEdit from './pages/ProductEdit';
import LoginIndex from './pages/LoginIndex';
import RegisterIndex from './pages/RegisterIndex';
import ActivateAccount from './pages/ActivateAccount';
import ShopList from './pages/ShopList';
import ShopProductList from './pages/ShopProductList';
import ListingProductList from './pages/ListingProductList';
import SaleOrderList from './pages/SaleOrderList';
import PurchaseOrderList from './pages/PurchaseOrderList';
import PurchasePool from './pages/PurchasePool';
import Dashboard from './pages/Dashboard';
import OrderFullfilList from './pages/OrderFullfilList';
const Loading = () => {
  return (
    <div className="loading-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh'}}>
      <Spin size="large" />
    </div>
  );
};


// const ProductsIndex = React.lazy(() => import('./pages/ProductsIndex'));
// const ProductDetail = React.lazy(() => import('./pages/ProductDetail'));
// const ProductsList = React.lazy(() => import('./pages/ProductsList'));
// const ProductEdit = React.lazy(() => import('./pages/ProductEdit'));
// const LoginIndex = React.lazy(() => import('./pages/LoginIndex'));
// const RegisterIndex = React.lazy(() => import('./pages/RegisterIndex'));
// const ActivateAccount = React.lazy(() => import('./pages/ActivateAccount'));
// const ShopList = React.lazy(() => import('./pages/ShopList'));
// const ShopProductList = React.lazy(() => import('./pages/ShopProductList'));
// const ListingProductList = React.lazy(() => import('./pages/ListingProductList'));
// const SaleOrderList = React.lazy(() => import('./pages/SaleOrderList'));
// const PurchaseOrderList = React.lazy(() => import('./pages/PurchaseOrderList'));
// const PurchasePool = React.lazy(() => import('./pages/PurchasePool'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  // {
  //   path: "/dashboard",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ProductsIndex />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/product/select_products",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ProductsIndex />
  //     </Suspense>
  //   ),
  // },

  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading />}>
        <LoginIndex />
      </Suspense>
    ),
  },
  {
    path: "/register",
    element: (
      <Suspense fallback={<Loading />}>
        <RegisterIndex />
      </Suspense>
    ),
  },
  {
    path: "/saved_products",
    element: (
      <Suspense fallback={<Loading />}>
        <ProductsList />
      </Suspense>
    ),
  },
  {
    path: "/product/edit",
    element: (
      <Suspense fallback={<Loading />}>
        <ProductEdit />
      </Suspense>
    ),
  },
  // {
  //   path: "/shop/shops",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ShopList />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/product/shop_products",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ShopProductList />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/product/listing_products",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ListingProductList />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/order/sale_orders",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <SaleOrderList />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/purchase/purchase_orders",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <PurchaseOrderList />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/purchase/purchase_products",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <PurchasePool />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    ),
    children: [
      { path: "dashboard", element: <ProductsIndex /> },
      { path: "product/select_products", element: <ProductsIndex /> },
      { path: "product/detail/:offerId", element: <ProductDetail /> },
      { path: "saved_products", element: <ProductsList /> },
      { path: "product/edit", element: <ProductEdit /> },
      { path: "shop/shops", element: <ShopList /> },
      { path: "product/shop_products", element: <ShopProductList /> },
      { path: "product/listing_products", element: <ListingProductList /> },
      { path: "order/sale_orders", element: <SaleOrderList /> },
      { path: "order/order_fullfill", element: <OrderFullfilList /> },
      { path: "purchase/purchase_orders", element: <PurchaseOrderList /> },
      { path: "purchase/purchase_products", element: <PurchasePool /> },
      {
        path: "product/detail/:offerId",
        element: <ProductDetail />,
      },
    ],
  },
  {
    path: "/activate",
    element: (
      <Suspense fallback={<Loading />}>
        <ActivateAccount />
      </Suspense>
    ),
  },
]);

export default inject('userStore')((({ userStore }) => {

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}))

