import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { Card, Row, Col, Form, Input, Select, Button, Table, Checkbox, Radio, Tooltip, message, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';
import shopApi from '../servapi/shop';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import listingProductApi from '../servapi/listing_product';
import './ShopProductList.less';
import PushStatus from './component/status/PushStatus';

const RadioGroup = Radio.Group;

const { Option } = Select;

function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const RadioOptions = ({ onPushStore, onDelete, onGetData, selectedRowKeys = []}) => {
  const { t } = useTranslation();
  return (

    <RadioGroup className="ant-radio-group ant-radio-group-outline">
      <Radio.Button value="a" disabled>
      <span style={{ color: 'red', marginRight: '5px' }}>
          [{selectedRowKeys.length}]
        </span>
        <span>{t("productList.btns.selectedProduct")}</span>
      </Radio.Button>
      <Radio.Button value="push" onClick={onPushStore}>
        <span>{t("productList.btns.pushStore")}</span>
      </Radio.Button>
      <Radio.Button value="update" onClick={onGetData}>
        <span>{t("productList.btns.refreshData")}</span>
      </Radio.Button>
      <Radio.Button value="delete" onClick={onDelete}>
        <span>{t("productList.btns.deleteSeletedProduct")}</span>
      </Radio.Button>
    </RadioGroup>
  );
};

function transformData(json) {
  return {
    key: json.id.toString(),
    name: json.productName,
    cost: json.lowestPrice ? json.lowestPrice.toString() : 'N/A',
    source: json.platform,
    productUrl: json.productUrl,
    spuCode: json.spuCode,
    price: json.highestPrice,
    platform: json.platform || 'N/A',
    pushStatus: json.pushState ? 'Pushed' : 'Not Pushed',
    inventoryStatus: json.inventoryState ? 'In Stock' : 'Out of Stock',
    updateTime: json.updateTime || 'N/A',
  };
}

const ProductsTable = ({ currentShop = {} }) => {
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t, i18n } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });


  React.useEffect(() => {
    if (!currentShop.id) {
      return;
    }

    fetchShopProducts(currentShop.id, { pagination });
  }, [currentShop, pagination.current, pagination.pageSize]);

  const fetchShopProducts = async (id, { pagination }) => {
    const pageData = await shopApi.getShopProducts(id, {
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.shopProducts || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.totalElements,
    })
  }

  const onSelectChange = (record) => {
    const key = record.id;
    const selectedIndex = selectedRowKeys.indexOf(key);
    let newSelectedRowKeys = [...selectedRowKeys];

    if (selectedIndex === -1) {
      newSelectedRowKeys.push(key);
    } else {
      newSelectedRowKeys.splice(selectedIndex, 1);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onToggleAllRows = (checked) => {
    const newSelectedRowKeys = checked ? data.map((record) => record.id) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePushStore = () => {
    if (selectedRowKeys.length <= 0) {
      return;
    }

    const hide = message.loading("商品正在推送", 0);

    shopApi.pushProductsToShop(currentShop.id, {
      shopProductIds: selectedRowKeys
    })
      .then(() => {
        hide();
        message.success('商品推送完成')
      })
      .catch(console.log)
  }


  const handleDelete = async (record) => {
  }


  // const data = pageData?.content || [];
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
          checked={selectedRowKeys.length === data.length}
          onChange={(e) => onToggleAllRows(e.target.checked)}
        />
      ),
      key: 'selection',
      dataIndex: 'selection',
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%'}}>
          <Checkbox
            onChange={() => onSelectChange(record)}
            checked={selectedRowKeys.includes(record.id)}
          />
        </div>

      ),
      width: '5%',
    },
    {
      title: t("productList.table.name"),
      dataIndex: 'productName',
      key: 'productName',
      width: '25%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={record.productImage}
            alt={text}
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Link to={`/product/detail/${record.outProductId}`} target="_blank">
            <span style={{ color: '#000' }} >{text}</span>
          </Link>

        </div>
      ),
    },
    {
      title: t("productList.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      render: (_, record) => {
        let price = record.price;

        if (record.quoteType === 2) {
          const rangePrices = JSON.parse(record.rangePrices || '[]');

          if (rangePrices.length > 0) {
            const start = rangePrices[0];
            const end = rangePrices[rangePrices.length - 1];
            price = `${start.price} - ${end.price}`;
          }
        }
        return (
          <span>{t("common.currency.symbol")}{price}</span>
        )
      }
    },
    {
      title: (
        <Tooltip title={t("productList.table.pushStatus")}>
          {t("productList.table.pushStatus")}
          <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
        </Tooltip>
      ),
      dataIndex: 'pushState',
      key: 'pushState',
      width: '10%',
      render: (_, record) => <PushStatus status={record.pushState}/>
    },
    {
      title: t("productList.table.updateTime"),
      dataIndex: 'lastPushTime',
      key: 'lastPushTime',
      width: '15%',
      render: (_, record) => <span>{formatDate(record.lastPushTime)}</span>
    },
    {
      title: t("productList.table.action"),
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Popconfirm
          title={t("productList.confirmDelete")}
          onConfirm={() => handleDelete(record.id)} // 替换为实际删除逻辑
          okText={t("common.yes")}
          cancelText={t("common.no")}
        >
          <a>Delete</a>
        </Popconfirm>
      ),
      width: '10%',
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };



  return (
    <>
      <div className="patch flex-center-vertical">
        <RadioOptions onPushStore={handlePushStore} selectedRowKeys={selectedRowKeys} />
      </div>
      <div className="antd-table-wrapper table w-full">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{ x: 'max-content' }}
          pagination={pagination}
          rowKey="id"
          onChange={handleTableChange}
          className="products-table"
        />
      </div>
    </>

  );
}


export default props => {
  const { t } = useTranslation();
  const [currentShop, setCurrentShop] = useState({});
  const [shops, setShops] = useState([]);

  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        setCurrentShop(shops[0]);
      })
      .catch(console.log);
  }, []);

  const handleShopChange = id => {
    const shop = shops.filter(shop => shop.id === id)[0];

    setCurrentShop({
      ...shop,
    })
  }


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("productList.title")}
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <div className="saved-products">
          <Card>
            <div className="ant-card-body">
              <Form className="ant-legacy-form ant-legacy-form-inline block-item">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="店铺" className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="platform" value={currentShop && currentShop.id} onChange={handleShopChange}>
                        {
                          shops.map(shop => (<Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.productName")} className="ant-legacy-form-item-label-left">
                      <Input placeholder={t("productList.search.productPlaceHolder")} id="keyword" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.source")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="platform">
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.pushStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="pushState">
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.inventoryStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="inventoryState">
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16} style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="button" style={{ marginRight: 8 }}>{t("productList.search.searchBtn")}</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="button">{t("productList.search.clearBtn")}</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <Card className="products-table mt-[16px]">
            <ProductsTable currentShop={currentShop} />
          </Card>
        </div>
      </div>
    </>
  )
}