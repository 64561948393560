import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload, Tooltip, message } from 'antd';
import axios from 'axios';
import { SearchOutlined, CameraOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';

export default ({ onSearch, onSearchImageUpload, value }) => {
  const { t } = useTranslation()
  const [input, setInput] = React.useState(value);

  const handleInputChange = e => {
    setInput(e.target.value);
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      console.log('do validate');
      onSearch && onSearch(input);
      return;
    }
  }

  const handleUpload = async ({ file }) => {
    // 处理上传逻辑，可以在这里获取上传的文件信息
    console.log('Uploaded file:', file);
    // 这里可以根据实际需求处理上传后的操作
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await productApi.uploadSearchImage(formData);
      onSearchImageUpload(res);
    } catch(error) {
        // 处理上传失败的情况
        message.error('上传失败');
        console.error('上传失败:', error);
    }
  
    // axios.post('/api/v1/upload_search_image', formData)
    //   .then(response => {
       
    //   })
    //   .catch(error => {

    //   });
  };

  return (
    <div className="bg-white rounded-[28px] w-[660px] h-[56px] mt-[24px] flex-row justify-end ml-auto mr-auto relative">
      <input className="w-full h-full break-words text-black text-[16px] font-PingFangSC-Regular font-normal leading-[22px] whitespace-nowrap ml-[28px] outline-none border-none" type="text" value={input} placeholder={t('index.search.placeholder')} onChange={handleInputChange} onKeyDown={handleKeyPress} />
      <div className="rounded-[28px] w-[165px] h-[56px] ml-[15px] cursor-pointer flex-row justify-center items-center" style={{ background: '#0055CC' }} onClick={() => onSearch && onSearch(input)}>
        <div className="flex-row">
          <img
            className="w-[16px] h-[16px] mt-[3px] mr-[9px]"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge6013585618e443f63b9e303529910115e8f6df9e14287d1d429a8b26bbad3d5"
          />
          <span className="break-words text-white text-[16px] font-pingfang-semibold font-semibold text-right whitespace-nowrap leading-[22px]">{t("index.search.btnText")}</span>
        </div>
      </div>
      <Upload
        showUploadList={false}
        beforeUpload={() => false} // 取消默认上传行为
        onChange={handleUpload}
      >
        <Tooltip title={t("productIndex.search.uploadText")}>
          <Button className='index-search-upload-icon search-upload-icon'>
            <CameraOutlined style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      </Upload>
    </div>
  )
}