import React from 'react';
import RegisterForm from './component/RegisterForm';

import './RegisterIndex.less';

export default props => {


  return (
    <div className="canvas-bg">
      <RegisterForm />
    </div>
  )
}