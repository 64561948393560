import React, { useState } from 'react';
import Dashboard from './Dashboard';
import { Card, Row, Col, Form, Input, Select, Button, Table, Checkbox, Radio, Tooltip, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shopApi from '../servapi/shop';
import './ListingProductList.less';
import listingProductApi from '../servapi/listing_product';
import ShopListOverlay from './component/ShopListOverlay';

const RadioGroup = Radio.Group;

function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const RadioOptions = ({ onAddToShop }) => {
  const { t } = useTranslation();
  return (

    <RadioGroup className="ant-radio-group ant-radio-group-outline">
      <Radio.Button value="a" disabled>
        <span>{t("productList.btns.selectedProduct")}</span>
      </Radio.Button>
      <Radio.Button value="push" onClick={onAddToShop}>
        <span>{t("productList.btns.addToStore")}</span>
      </Radio.Button>
      <Radio.Button value="update" disabled>
        <span>{t("productList.btns.refreshData")}</span>
      </Radio.Button>
      <Radio.Button value="delete" disabled>
        <span>{t("productList.btns.deleteSeletedProduct")}</span>
      </Radio.Button>
    </RadioGroup>
  );
};


const ProductsTable = () => {
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t, i18n } = useTranslation();
  const [shopListVisible, setShopListVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });


  React.useEffect(() => {
    fetchListingProducts({ pagination });

  }, [pagination.current, pagination.pageSize]);

  const fetchListingProducts = async ({ pagination }) => {
    const pageData = await listingProductApi.getListingProducts({
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.products || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const onSelectChange = (record) => {
    const key = record.id;
    const selectedIndex = selectedRowKeys.indexOf(key);
    let newSelectedRowKeys = [...selectedRowKeys];


    if (selectedIndex === -1) {
      newSelectedRowKeys.push(key);
    } else {
      newSelectedRowKeys.splice(selectedIndex, 1);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onToggleAllRows = (checked) => {
    const newSelectedRowKeys = checked ? data.map((record) => record.id) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };


  // const data = pageData?.content || [];
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
          checked={selectedRowKeys.length === data.length}
          onChange={(e) => onToggleAllRows(e.target.checked)}
        />
      ),
      key: 'selection',
      dataIndex: 'selection',
      render: (_, record) => (
        <Checkbox
          onChange={() => onSelectChange(record)}
          checked={selectedRowKeys.includes(record.id)}
        />
      ),
      width: '5%',
    },
    {
      title: t("productList.table.name"),
      dataIndex: 'productName',
      key: 'productName',
      width: '25%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={record.productImage}
            alt={text}
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Link to={`/product/detail/${record.outProductId}`} target="_blank">
            <span style={{ color: '#000' }} >{text}</span>
          </Link>

        </div>
      ),
    },
    {
      title: t("productList.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      render: (_, record) => {
        let price = record.price;

        if (record.quoteType === 2) {
          const rangePrices = JSON.parse(record.rangePrices || '[]');

          if (rangePrices.length > 0) {
            const start = rangePrices[0];
            const end = rangePrices[rangePrices.length - 1];
            price = `${start.price} - ${end.price}`;
          }
        }
        return (
          <span>{t("common.currency.symbol")}{price}</span>
        )
      }
    },
    {
      title: t("productList.table.updateTime"),
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: '15%',
      render: (_, record) => <span>{formatDate(record.updatedTime)}</span>
    },
    {
      title: t("productList.table.action"),
      key: 'action',
      fixed: 'right',
      render: () => <a>Delete</a>,
      width: '10%',
    },
  ];

  const handleAddToShops = async (shopIds) => {
    if (selectedRowKeys.length <= 0) {
      return;
    }

    if (shopIds.length <= 0) {
      return;
    }

    const hide = message.loading("正在添加到店铺商品", 0);

    shopApi.addProductsToShops({
      shopIds,
      listingProductIds: selectedRowKeys
    })
      .then(() => {
        hide();
        message.success('添加到店铺商品完成')
      })
      .catch(console.log);

    setShopListVisible(false);
  }

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };



  return (
    <>
      <div className="patch flex-center-vertical">
        <RadioOptions onAddToShop={() => setShopListVisible(true)} />
      </div>
      <div className="antd-table-wrapper table w-full">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{ x: 'max-content' }}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="id"
          className="products-table"
        />
      </div>
      <ShopListOverlay visible={shopListVisible} onConfirm={handleAddToShops} onCancel={() => setShopListVisible(false)}/>
    </>

  );
}


export default props => {
  const { t } = useTranslation();


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("productList.title")}
                </h3>
              </div>
            </Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              <Button href="" style={{ marginRight: 15 }}>
                批量导入商品
              </Button>
              <Button type="primary" href="" className="add_self_product_button">
                添加自定义商品
              </Button>
            </Col> */}
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <div className="saved-products">
          <Card>
            <div className="ant-card-body">
              <Form className="ant-legacy-form ant-legacy-form-inline block-item">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.productName")} className="ant-legacy-form-item-label-left">
                      <Input placeholder={t("productList.search.productPlaceHolder")} id="keyword" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.source")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="platform">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.pushStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="pushState">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.inventoryStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="inventoryState">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16} style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="button" style={{ marginRight: 8 }}>{t("productList.search.searchBtn")}</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="button">{t("productList.search.clearBtn")}</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </div>
          </Card>
          <Card className="products-table mt-[16px]">
            <ProductsTable />
          </Card>
        
        </div>
      </div>
    </>
  )
}